<template>
  <div>
    <listitemspages
      :selitem="selitem"
      :qSelect="Qselect"
      :qSelectall="Qselectall"
      :PageResponse="'reparations_pages'"
      :AllResponse="'reparationslist'"
      :title="'Reparations List'"
      :headers="headers"
      :field="'CODE'"
      :filename="'Reparations'"
      :sheetname="'Reparations'"
      :showedit="false"
      :Add="true"
      :del="false"
      :list_options="false"
      :key="klist"
      :search_elm="filtre"
      @open="open_reparation"
      @rowselect="select_reparation"
    />
    <reparationform
      :dialog="rep_dialog"
      :item="selitem"
      :user_list="user_list"
      @close_reparation="close_reparation"
      @refresh_reparation="refresh_reparation"
      :status_list="status_list"
      :key="kform"
      :well_list="well_list"
      :wellfailures="wellfailures"
      :barriers="barriers"
      :suppliers="suppliers"
      :vsizes_list="vsizes_list"
    >
    </reparationform>
  </div>
</template>
<script>
import REPARATION_LIST from "../graphql/Reparation/REPARATIONS_LIST.gql";
import REPARATION_pages from "../graphql/Reparation/REPARATIONS_PAGES.gql";
import DATA from "../graphql/Reparation/DATA.gql";
import DOCS from "../graphql/Reparation/DOCS.gql";
export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
    reparationform: () => import("../components/RepairForm.vue"),
  },
  data() {
    return {
      status_list: [],
      filtre: "",
      user_list: [],
      selitem: {},
      details: [],
      wells: [],
      files_list: [],
      klist: 0,
      kform: 1000,
      well_list: [],
      wellfailures: [],
      rep_dialog: false,
      headers: [
        {
          text: "Code",
          value: "code",
          selected: true,
          slot: "href",
          enum: "CODE",
        },
        {
          value: "wellname",
          text: "Well",
          selected: true,
        },
        {
          text: "Repair Date",
          value: "date_reparation",
          selected: true,
        },
        {
          value: "userName",
          text: "Executed by",
          selected: true,
        },
        {
          value: "ownerName",
          text: "Evalued by",
          selected: true,
        },

        {
          value: "status",
          text: "Statut",
          selected: true,
        },
        {
          text: "Waiver",
          slot: "col_btn1",
          selected: false,
          icon: "mdi-pencil",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
      failures: [],
      barriers: [],
      loading: false,
      waiter: false,

      vsizes_list: [],
      suppliers: [],
    };
  },
  watch: {
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },
  async created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
    this.getdata();
  },
  computed: {
    Qselect() {
      return REPARATION_pages;
    },
    Qselectall() {
      return REPARATION_LIST;
    },
  },
  methods: {
    async getdata() {
      this.$apollo
        .query({
          query: DATA,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.well_list = data.data.reparationWell_list;
          this.barriers = data.data.wmf_list;
          this.user_list = data.data.users;
          this.vsizes_list = data.data.vsizes_list;
          this.suppliers = data.data.suppliers;
          this.status_list = data.data.status_list;
        })
        .catch(() => {});
    },
    refresh_reparation() {
      this.klist++;
    },
    close_reparation() {
      this.rep_dialog = false;
    },
    async open_reparation(item) {
      this.selitem = item;
      let k = this.well_list.findIndex((w) => w.id == item.well_id);
      if (k < 0 && item.well_id)
        this.well_list.push({ id: item.well_id, wellname: item.wellname });

      if (item.well_id) {
        let v = {
          Well_id: parseInt(item.well_id),
        };
        let r = await this.$maj(DOCS, v);
        if (r.ok) {
          //----------
          this.wellfailures = r.data.getfailures_list;
          //------------
        }
      }
      this.rep_dialog = true;
      this.kform++;
    },
    async select_reparation(item) {
      this.selitem = item;
    },
  },
};
</script>
