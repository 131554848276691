<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          <v-row
            align="center"
            justify="center"
            style="font-weight: bold; margin-right: 60px"
          >
            <v-col cols="12" sm="1" md="1"> </v-col>
            <v-col cols="12" sm="2" md="2">Operating I</v-col>
            <v-col cols="12" sm="2" md="1">Opening turns</v-col>
            <v-col cols="12" sm="2" md="1">Closing turns</v-col>

            <v-col cols="12" sm="2" md="1">Initial pressure</v-col>
            <v-col cols="12" sm="2" md="1">Final pressure</v-col>
            <v-col cols="12" sm="2" md="1">Time</v-col>
            <v-col cols="12" sm="2" md="2">Coment</v-col>
          </v-row>
          <v-row :key="kall">
            <v-col cols="12" sm="12" md="12">
              <v-row
                class="rowh2"
                dense
                align="center"
                justify="center"
                v-for="item in xtrees_list"
                :key="item.component_id"
              >
                <v-col cols="12" sm="2" md="1">
                  <div style="font-weight: bold; margin-bottom: 20px">
                    <span>{{ item.label }}</span>
                  </div></v-col
                >
                <v-col cols="12" sm="2" md="2">
                  <v-select
                    style="padding-right: 8px"
                    v-model="item.operat"
                    outlined
                    dense
                    :label="item.component"
                    :items="['Easy', 'Hard']"
                    @change="get_failures()"
                    @input="changes"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="2" md="1" align-self="center">
                  <v-text-field
                    class="rowh"
                    return-object
                    autocomplete="off"
                    v-model="item.o_turns"
                    type="number"
                    hide-spin-buttons
                    outlined
                    dense
                    @change="get_failures()"
                    @input="changes"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    class="rowh"
                    return-object
                    autocomplete="off"
                    v-model="item.c_turns"
                    :disabled="item.o_turns == 0"
                    type="number"
                    hide-spin-buttons
                    outlined
                    dense
                    @change="get_failures()"
                    @input="changes"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    class="rowh"
                    autocomplete="off"
                    return-object
                    v-model="item.i_pressure"
                    :disabled="item.o_turns == 0"
                    type="number"
                    hide-spin-buttons
                    outlined
                    dense
                    @change="get_failures()"
                    @input="changes"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    class="rowh"
                    return-object
                    autocomplete="off"
                    v-model="item.f_pressure"
                    :disabled="item.o_turns == 0"
                    type="number"
                    hide-spin-buttons
                    outlined
                    dense
                    @change="get_failures()"
                    @input="changes"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <div
                    style="
                      font-weight: bold;
                      margin-bottom: 20px;
                      margin-left: 20px;
                    "
                  >
                    <span>10</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="2" md="3">
                  <v-text-field
                    class="rowh"
                    return-object
                    autocomplete="off"
                    v-model="item.comment"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col> </v-row
              ><v-row class="font_style">
                * Pression in {{ pressure_unit }} </v-row
              ><v-row class="font_style">
                * 0 turns= failed to close/open </v-row
              ><br />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    xtrees: Array,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
    barriers: Array,
    all_failures_list: Array,
  },
  data() {
    return {
      kall: 0,
      xtrees_list: [],
      pressure_unit: "",
      test_failures: [],
      well_failures: [],
      t_changes: 0,
    };
  },

  mounted() {
    //     <!-- :rules="[(v) => !!v || 'Operating required']" -->
    this.pressure_unit = this.$store.state.options[0].pressure;
    this.xtrees_list = Object.assign([], this.xtrees);
    this.well_failures = Object.assign([], this.failures_list);

    this.$emit("changes", this.xtrees_list, this.failures_list, this.t_changes);
  },
  computed: {},
  watch: {},
  methods: {
    set_failures() {
      this.get_failures();
      return this.test_failures;
    },
    validate() {
      return this.$refs.form.validate();
    },

    valuechanged_open(item) {
      //fail to open
      if (item.o_turns) {
        if (item.o_turns == "0") {
          item.f_pressure = null;
          item.i_pressure = null;
          item.c_turns = null;
          this.kall++;
          this.set_falure(item, 188, 2);
        } else if (
          parseInt(item.o_turns) !=
          parseInt(item.v_turns ? item.v_turns : item.o_turns)
        ) {
          this.set_falure(item, 220, 2);
        }
      }
      this.t_changes++;
    },
    valuechanged_close(item) {
      if (item.c_turns) {
        if (item.c_turns == 0) this.set_falure(item, 189, 3);
        //fail to close
        else if (
          parseInt(item.c_turns) !=
          parseInt(item.v_turns ? item.v_turns : item.c_turns)
        ) {
          //partial close
          this.set_falure(item, 221, 3);
        }
      }
      this.t_changes++;
    },
    valuechanged_operat(item) {
      if (item.operat == "Hard") this.set_falure(item, 222, 4);
      this.t_changes++;
    },
    valuechanged_pressure(item) {
      if (item.i_pressure == 0) item.i_pressure = null;
      if (item.f_pressure == 0) item.f_pressure = null;
      if (item.i_pressure && item.f_pressure) {
        if (item.i_pressure != item.f_pressure) {
          if (Math.abs(item.i_pressure - item.f_pressure) >= item.pressure)
            this.set_falure(item, 186, 1);
          else if (Math.abs(item.i_pressure - item.f_pressure) < item.pressure)
            this.set_falure(item, 187, 1);
        }
      }
      this.t_changes++;
    },
    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        statut_id: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        g_id: gid,
        well_tubular_id: item.well_tubular_id,
        testtype: this.editedItem.testtype_id,
      };

      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );

      //this.$calc_single(this.barriers, this.failures_list, fail);
    },
    get_failures() {
      this.test_failures = [];
      // get actual failures
      this.xtrees_list.forEach((item) => {
        this.valuechanged_open(item);
        this.valuechanged_close(item);
        this.valuechanged_operat(item);
        this.valuechanged_pressure(item);
      });
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter(
          (x) => x.cmp_code != "0" && x.failure_id > 0 && x.statut_id < 2
        ),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );
      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      //  multiples.forEach((m) => this.test_failures.push(m));
      this.$emit(
        "changes",
        this.xtrees_list,
        this.test_failures,
        this.t_changes
      );
    },
    changes() {
      this.$emit(
        "changes",
        this.xtrees_list,
        this.test_failures,
        this.t_changes
      );
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
