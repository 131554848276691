<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showForm"
      max-width="1400px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          {{ item.message }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              Additional Tests
              <TestAdditional
                :tests="tests"
                :readonly="false"
                :apm_id="parseInt(item.id)"
                :well_id="parseInt(item.well_id)"
                :teststypes_list="teststypes_list"
              ></TestAdditional>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <!-- <v-checkbox
            v-model="annulus"
            :label="'include ' + item.annulus + ' Alert'"
          ></v-checkbox> -->
          <v-btn color="blue darken-1" @click="complete" v-if="task_exist"
            >{{ task_exist.task_type == 0 ? "Complete" : "Evaluate" }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmdialog ref="validation" />
    <TaskClose @completed="complete" :ref="'task_close'"> </TaskClose>
  </v-form>
</template>
<script>
import APM_ALERT_UPDATE from "../graphql/WellTest/APM/APM_ALERT_UPDATE.gql";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    TestAdditional: () => import("../components/Test_Additional.vue"),
    TaskClose: () => import("../components/TaskCloseForm.vue"),
  },
  props: {
    showForm: Boolean,
    item: Object,
    teststypes_list: Array,
    tests: Array,
  },
  data() {
    return {
      editedItem: {},
      annulus: false,
      tests_selected: [],
    };
  },
  watch: {},
  mounted() {
    if (this.showForm) {
      this.editedItem = Object.assign({}, this.item);
      console.log(this.editedItem);
    }
  },
  computed: {
    task_exist() {
      let t = null;
      if (this.editedItem)
        if (this.editedItem.tasks) {
          let l = this.editedItem.tasks.filter((elm) => elm.status_id < 2);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (element.users) {
              let i = element.users.findIndex(
                (elm) =>
                  elm.user_id == this.$store.state.me.id && elm.status_id < 2
              );
              if (i >= 0) {
                t = element.users[i];
                break;
              }
            }
          }
        }
      console.log(t);
      return t;
    },
  },
  methods: {
    async complete() {
      this.$refs.task_close.open(this.task_exist);
    },
    closeDialog() {
      this.$emit("close");
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.validation.open(
            "Confirmation",
            "Disable this Alert !!-Are you sur ?",
            { color: "orange darken-3" }
          )
        ) {
          this.$apollo
            .query({
              query: APM_ALERT_UPDATE,
              variables: {
                id: this.item.id,
                //annulus: this.annulus ? this.item.well_tubular_id : null,
                comment: this.editedItem.comment,
                user_id: parseInt(this.$store.state.me.id),
              },
              fetchPolicy: "no-cache",
            })
            .then(() => {
              this.$store.dispatch("Changed", true);
              this.$emit("disabled", this.item);
              this.closeDialog();
            })
            .catch(() => {});
        }
      }
    },
  },
};
</script>
<style scoped>
.rowh {
  margin-left: 12px;
  height: 30px !important;
}
</style>
