datepicker
<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <v-spacer>{{
            editedItem.code ? "Repair " + editedItem.code : "New Repair"
          }}</v-spacer
          >{{ editedItem.status }}
        </v-card-title>
        <v-card-text
          ><br />
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                :items="well_list"
                v-model="editedItem.well_id"
                item-value="id"
                item-text="wellname"
                label="Well"
                dense
                @change="well_change"
                :rules="[(v) => !!v || 'Well required']"
                :readonly="!edit || this.editedItem.statut_id > 2"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="users_exe"
                v-model="editedItem.users"
                item-value="id"
                item-text="name"
                label="Repaired by"
                :rules="[(v) => !!v || 'Repaired by required']"
                @change="changes++"
                :readonly="!edit"
                dense
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" sm="4" md="4">
              <v-select
                :items="owners_exe"
                v-model="editedItem.owners"
                item-value="id"
                item-text="name"
                label="Evalued by"
                :rules="[(v) => !!v || 'Evalued by required']"
                @change="changes++"
                :readonly="!edit"
                dense
              >
              </v-select>
            </v-col> -->
            <v-col cols="12" sm="2" md="2">
              <datepicker
                label="Repair Date"
                v-model="editedItem.date_reparation"
                :edit="edit"
                :clearable="false"
                @change="changes++"
                :key="cs"
              ></datepicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                outlined
                dense
                v-model="editedItem.comment"
                label="Comment"
                row-height="30"
                rows="2"
                @input="changes++"
                variant="outlined"
                auto-grow
                :readonly="!edit"
              ></v-textarea>
            </v-col>
          </v-row>
          <div class="scrollable-content" v-if="editedItem.id > 0">
            <v-tabs v-model="tab0" class="my-tabs" align-tabs="left">
              <v-tab key="1" href="#1"> Elements </v-tab>
              <v-tab key="2" href="#2"> Additional tests </v-tab>

              <v-tab key="3" href="#3">
                Attachments
                <v-icon> mdi-paperclip </v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab0">
              <v-tab-item :value="'1'"
                ><br />
                <v-row v-if="edit">
                  <v-col cols="12" sm="8" md="8">
                    <v-select
                      :items="currentfailures"
                      v-model="current_failure"
                      item-text="issue"
                      item-value="id"
                      label="Failure"
                      outlined
                      dense
                      return-object
                    >
                      <template v-slot:selection="data">
                        <div>
                          {{ data.item.component }} - {{ data.item.issue }}
                        </div>
                      </template>
                      <template v-slot:item="data">
                        <div
                          style="display: flex; justify-content: space-between"
                        >
                          <span>{{ data.item.component }}</span
                          >:&nbsp;&nbsp;
                          <span>{{ data.item.issue }}</span>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-spacer> </v-spacer>
                    <v-btn @click="add_repair" :disabled="!current_failure.id">
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
                <listitems
                  :list="editedItem.details"
                  :headers="headers"
                  :toolbar="false"
                  :key="klist"
                  @col_btn1_click="replace_component"
                  @col_btn2_click="delete_repair"
                >
                </listitems>
              </v-tab-item>
              <v-tab-item :value="'2'"> </v-tab-item>
              <v-tab-item :value="'3'">
                <filelist
                  :item="editedItem"
                  :editer="true"
                  :doc_type="11"
                  :isform="false"
                  :auth="'01007'"
                  :well_id="parseInt(editedItem.well_id)"
                >
                </filelist>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            @click="complete"
            v-if="task_exist && editedItem.statut_id == 5"
            >{{ task_exist.task_type == 0 ? "Complete" : "Evaluate" }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(5)"
            :disabled="changes != 1"
            v-if="editedItem.statut_id == 4"
          >
            Complete
          </v-btn>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(4)"
            :disabled="changes == 1"
          >
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmdialog ref="confirm" />
    <TaskClose @completed="repair_Complete" :ref="'task_close'"> </TaskClose>
    <component
      :is="!isComponentClose ? 'componentform' : null"
      :showform="!isComponentClose"
      :well_id="parseInt(editedItem.well_id)"
      :modify="true"
      :suppliers="suppliers"
      :item="component"
      :vsizes_list="vsizes_list"
      @close_cmp="close_cmp"
      @close="isComponentClose = true"
      :key="tcomp"
      :replaceMode="true"
    />
  </v-form>
</template>

<script>
import SaveReparation from "../graphql/Reparation/SaveReparation.gql";
import REPARATION from "../graphql/Reparation/REPARATIONS_PAGES.gql";
import DOCS from "../graphql/Reparation/DOCS.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    filelist: () => import("./FileList.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    // uploader: () => import("../components/FilesUploader.vue"),
    TaskClose: () => import("../components/TaskCloseForm.vue"),
    componentform: () => import("../components/ComponentForm.vue"),
  },
  props: {
    dialog: Boolean,
    item: Object,
    user_list: Array,

    well_list: Array,
    barriers: Array,
    wellfailures: Array,
    status_list: Array,
    vsizes_list: Array,
    suppliers: Array,
    well: Object,
  },
  data() {
    return {
      wellfailures_list: [],
      isComponentClose: true,
      component: {},
      failures: [],
      tab0: 1,
      klist: 100,
      tcomp: 10000,
      changes: 0,
      editedItem: {},
      cs: 1000,
      current_failure: {},
      headers: [
        {
          text: "Component",
          value: "component",
          selected: true,
        },
        {
          value: "failure",
          text: "Failure",
          selected: true,
        },
        {
          value: "replaced_txt",
          text: "Replaced",
          selected: true,
        },
        {
          text: "",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-tools",
          width: "10",
          valeur: 0,
          sortable: false,
        },
        {
          text: "",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.changes = 1;
    });
  },
  watch: {},
  mounted() {
    if (this.dialog) {
      this.editedItem = Object.assign({}, this.item);
      if (this.wellfailures)
        this.wellfailures_list = Object.assign([], this.wellfailures);
      this.update_headers();
      this.cs++;
      this.klist++;
    }
    this.changes = 1;
  },
  computed: {
    today() {
      return this.$store.state.today;
    },
    edit() {
      return (
        (this.$store.state.auth.includes("02006") ||
          this.$store.state.auth.includes("03006") ||
          this.$store.state.isadmin) &&
        this.editedItem.statut_id != 5 &&
        this.editedItem.statut_id != 6
      );
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("02006"));

      return l;
    },
    owners_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("04006"));

      return l;
    },

    currentfailures() {
      if (this.editedItem.details)
        return this.wellfailures_list.filter(
          (item) =>
            !this.editedItem.details.some((x) => x.failure_id == item.id)
        );
      else return this.wellfailures_list;
    },
    task_exist() {
      let t = null;
      console.log("gggggggggggg");
      console.log(this.editedItem);
      if (this.editedItem)
        if (this.editedItem.tasks) {
          console.log(this.editedItem.tasks);
          let l = this.editedItem.tasks.filter((elm) => elm.status_id < 2);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (element.users) {
              let i = element.users.findIndex(
                (elm) =>
                  elm.user_id == this.$store.state.me.id && elm.status_id < 2
              );
              if (i >= 0) {
                t = element.users[i];

                break;
              }
            }
          }
        }

      return t;
    },
  },
  methods: {
    update_headers() {
      this.headers[3].selected = this.edit;
      this.headers[4].selected = this.edit;
    },
    async well_change() {
      if (this.editedItem.well_id) {
        let v = {
          Well_id: parseInt(this.editedItem.well_id),
        };
        let r = await this.$maj(DOCS, v);
        if (r.ok) {
          //----------
          this.wellfailures_list = r.data.getfailures_list;
          this.changes++;
          //------------
        }
      }
    },
    close_cmp(item) {
      if (item) {
        this.editedItem.details
          .filter((f) => f.component_id == this.component.component_id)
          .forEach((c) => {
            c.replaced_txt = "Replaced";
            c.replaced = true;
          });
        this.klist++;
        this.changes++;
      }
      this.isComponentClose = true;
    },

    add_repair() {
      if (!this.editedItem.details) this.editedItem.details = [];
      this.editedItem.details.push({
        id: -this.current_failure.id,
        component: this.current_failure.component,
        failure: this.current_failure.issue,
        failure_id: this.current_failure.id,
        reparation_id: this.editedItem.id,
        cmp_id: this.current_failure.cmp_id,
        well_tubular_id: this.current_failure.well_tubular_id,
        type_id: this.current_failure.type_id,
        repared: true,
        replaced: false,
        deleted: false,
      });
      this.current_failure = {};
      this.klist++;
      this.changes++;
    },
    replace_component(item) {
      //   if (item.type_id == 95)
      {
        this.component = {
          id: -1,
          label: item.component,
          component_id: item.component_id,
          well_id: item.well_id,
          type_id: item.type_id,
        };
        this.tcomp++;
        this.isComponentClose = false;
      }
      // console.log(item);
    },
    async delete_repair(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Are you sûr to update cements data?",
          { color: "orange darken-3" }
        )
      ) {
        this.editedItem.details.splice(item.index, 1);
        this.klist++;

        this.changes++;
      }
    },
    refresh() {
      this.$emit("refresh");
    },
    closeDialog() {
      this.$emit("close_reparation");
    },

    recalc_failures() {
      let wel_failures = [];
      this.failures.forEach((x) => {
        if (
          x.failure_id > 0 &&
          x.statut_id < 2 &&
          this.editedItem.details.findIndex(
            (r) => x.id == r.failure_id && !r.deleted
          ) >= 0
        )
          wel_failures.push(x);
      });
      let failure = {
        cmp_code: "-2",
      };
      let multiples = this.$repaire_failure(wel_failures, this.barriers);
      if (multiples.length > 0) {
        failure.cmp_code = "0";
        failure.multiple = "Multiple failure";
        failure.id = 0;
        failure = multiples.reduce(function (prev, current) {
          if (+current.failure_taux < +prev.failure_taux) {
            return current;
          } else {
            return prev;
          }
        });
      }
      // });
      return failure;
    },
    async repair_Complete() {
      let r = await this.$requette(
        REPARATION,
        {
          first: 1,
          where: {
            column: "ID",
            value: this.editedItem.id,
          },
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.reparations_pages.data.length > 0) {
          this.editedItem.statut_id =
            r.data.reparations_pages.data[0].statut_id;
          this.editedItem.status = r.data.reparations_pages.data[0].status;
          this.editedItem.tasks = r.data.reparations_pages.data[0].tasks;

          if (this.editedItem.statut_id == 5) {
            this.update_failure();
          }
        }
      }

      // this.update_failure();
    },
    async complete() {
      this.$refs.task_close.open(this.task_exist);
    },
    async save(statut) {
      //
      if (this.$refs.form.validate()) {
        let fls = null;

        let ok = true;
        if (statut == 5)
          ok = await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update cements data?",
            { color: "orange darken-3" }
          );
        if (ok) {
          let rep = {
            id: this.editedItem.id ? this.editedItem.id : -1,
            well_id: this.editedItem.well_id,
            comment: this.editedItem.comment,
            users: this.editedItem.users,
            owners: this.editedItem.owners,
            statut_id: statut,
            date_reparation: this.editedItem.date_reparation,
          };
          let details = [];
          if (this.editedItem.details)
            this.editedItem.details.forEach((x) => {
              details.push({
                id: x.id,
                cmp_id: x.cmp_id,
                well_tubular_id: x.well_tubular_id,
                failure_id: x.failure_id,
                reparation_id: this.editedItem.id,
                repared: true,
                replaced: x.replaced,
              });
            });

          this.$apollo
            .query({
              query: SaveReparation,
              variables: {
                reparation: rep,
                details: details,
                multiple: fls,
                user_id: parseInt(this.$store.state.me.id),
              },
              fetchPolicy: "no-cache",
            })
            .then((data) => {
              this.editedItem.id = data.data.SaveRepair.id;
              this.editedItem.code = data.data.SaveRepair.code;
              this.editedItem.statut_id = data.data.SaveRepair.statut_id;
              let k = (this.editedItem.status = this.status_list.findIndex(
                (elm) =>
                  elm.status_id == this.editedItem.statut_id &&
                  elm.document == "Reparation"
              ));
              if (k >= 0) this.editedItem.status = this.status_list[k].name;

              //UPDATE LIST FAILURE IF NEW REPARATION
              this.$emit("refresh_reparation");

              if (this.editedItem.statut_id == 5) this.repair_Complete();
              //
              this.update_headers();
              this.changes = 1;
            })
            .catch(() => {});
        }
      }
    },
    async update_failure() {
      //
      let fls = null;
      fls = await this.recalc_failures();
      let rep = {
        id: this.editedItem.id,
      };
      let details = [];
      if (this.editedItem.details)
        this.editedItem.details.forEach((x) => {
          details.push({
            id: x.id,
            cmp_id: x.cmp_id,
            well_tubular_id: x.well_tubular_id,
            failure_id: x.deleted ? -1 : x.failure_id,
            reparation_id: this.editedItem.id,
            repared: true,
          });
        });
      this.$apollo
        .query({
          query: SaveReparation,
          variables: {
            reparation: rep,
            details: details,
            multiple: fls,
            user_id: parseInt(this.$store.state.me.id),
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          // this.editedItem.id = data.data.SaveRepair.id;
          // this.editedItem.code = data.data.SaveRepair.code;
          // this.editedItem.statut_id = data.data.SaveRepair.statut_id;
          // this.editedItem.status = data.data.SaveRepair.status;
          // this.update_headers();
          // this.$emit("refresh_reparation");
          // this.changes = 1;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.scrollable-container {
  max-height: 600px;
  /* Hauteur maximum du conteneur */
  overflow-y: auto;
  /* Active le scroll vertical */
  padding: 10px;
  margin-top: 10px;
}

.rowh {
  margin-left: 12px;
  height: 25px !important;
}

.vrow {
  flex-wrap: wrap;
}
</style>
<style>
.rowh0 {
  height: 30px !important;
}

.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
</style>
