<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-toolbar flat>
        {{ selected_well.wellname }}
        <v-spacer></v-spacer>
        <!-- <a
          href="#"
          @click="downloadsvg"
          class="link-download btn btn-primary btn-sm"
          >Download</a
        > -->
        <div>
          <v-btn id="menu-activator" plain>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <v-menu activator="#menu-activator">
            <v-list>
              <v-list-item
                v-for="menu in menuList"
                :key="menu.id"
                :value="menu.id"
              >
                <template v-slot:default>
                  <v-list-item-action start>
                    <v-checkbox v-model="menu.checked"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-title>{{ menu.label }}</v-list-item-title>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar>
    </v-col>
    <v-col cols="5" sm="6" md="5">
      <component_svg
        style="stroke-width: 0px; background-color: white"
        :zoom="zoom"
        :well="well_svg"
        :key="ksvg1"
        :components_list="components_list"
        :components_list_details="components_list_details"
        :failures_cmp_list="failures_cmp_list"
        :formations_list="wellsformations"
        :barriers="barriers"
        :failures_list="failures_list"
        log="main1"
        :dialogform="dialogform"
        :failures="failures"
        :components_other="components_other"
        @add="add_failure"
      />
    </v-col>

    <v-col cols="7" sm="6" md="7">
      Single failures:
      <listitems
        :title="'Single Failures'"
        :list="failures"
        :headers="headers"
        :showstd="true"
        :toolbar="false"
        :hdf="false"
        :master="true"
        :Get_suf="'failure'"
        :key="kfailure"
        :add="false"
        :del="true"
        :qDelete="Qfailure_delete"
        @rowselect="FailureSelect"
        @delete="kfailure++"
        @col_btn1_click="FailureOpen"
      >
      </listitems>

      <div v-if="selected.includes(2)">
        Corrosion test
        <listitems
          :title="'Corrosion'"
          :list="corrosion_barriers"
          :toolbar="false"
          :headers="corrosionheaders"
          :key="kcorrosion"
          :showstd="true"
          :add="false"
          :del="false"
        >
        </listitems>
      </div>
      <div v-if="selected.includes(3)">
        Cements test
        <listitems
          :title="'Cement'"
          :list="cement_barriers"
          :toolbar="false"
          :headers="cementheaders"
          :key="kcement"
          :showstd="true"
          :add="false"
          :del="false"
        >
        </listitems>
      </div>
      <div v-if="selected.includes(4)">
        <div v-if="apm.length > 0">
          <apmform :apms="apm"></apmform>
        </div>
        <div v-else>No APM TEST</div>
      </div>

      <div v-if="selected.includes(5)">
        <div v-if="listBO.length > 0">
          <boform :list="listBO"></boform>
        </div>
        <div v-else>No BO TEST</div>
      </div>
      <div v-if="selected.includes(6)">
        <div v-if="test_dhsv.length > 0">
          <dhsvform :test_dhsv="test_dhsv[0]"></dhsvform>
        </div>
        <div v-else>No DHSV TEST</div>
      </div>

      <div v-if="selected.includes(7)">
        <div v-if="valvesXT.length > 0">
          <valveform :list="valvesXT" :test="'Xtree '"></valveform>
        </div>
        <div v-else>No Xtree TEST</div>
      </div>
      <div v-if="selected.includes(8)">
        <div v-if="valvesWH.length > 0">
          <valveform :list="valvesWH" :test="'WellHead '"></valveform>
        </div>
        <div v-else>No WellHead TEST</div>
      </div>
      <div v-if="selected.includes(9)">
        <div v-if="downs.length > 0">
          <downform :list="downs"></downform>
        </div>
        <div v-else>No Downhole TEST</div>
      </div>
      <div v-if="selected.includes(1)">
        Map
        <GmapMap
          ref="mapRef"
          :center="center"
          :zoom="zoom_map"
          :key="kmap"
          map-type-id="satellite"
        >
          <GmapMarker :position="center"> </GmapMarker>
        </GmapMap>
      </div>
      <givenform
        :dialog="givendialog"
        :wellstypes="wellstypes"
        :failure="failure"
        :given="given"
        :given_action="given_action"
        @close_given="close_given"
        :key="kga"
      ></givenform>

      <waiter :showWait="showWait"> </waiter>
    </v-col>
  </v-row>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"
/>
<script>
//import Chart from "chart.js";
import WELL from "../graphql/Well/WELL.gql";
import CMP_BARRIERS from "../graphql/Tests/CMP_BARRIERS.gql";

import CREATE_TEST_FAILURE from "../graphql/Tests/CREATE_TEST_FAILURE.gql";
import DELETE_TEST_FAILURE from "../graphql/Tests/DELETE_TEST_FAILURE.gql";
import UPDATE_TEST_FAILURE from "../graphql/Tests/UPDATE_TEST_FAILURE.gql";
import GIVEN_ACTION from "../graphql/Tests/GIVEN_ACTION.gql";
//import ALL_FP from "../graphql/FingerPrint/FPS_ALL_DETAIL.gql";
//import * as saveSvgAsPng from "save-svg-as-png";
export default {
  components: {
    component_svg: () => import("../components/ComponentSVG.vue"),
    listitems: () => import("../components/ListItems.vue"),
    waiter: () => import("../components/Widgets/Waiter.vue"),
    apmform: () => import("../components/TestForms/APM.vue"),
    boform: () => import("../components/TestForms/BO.vue"),
    dhsvform: () => import("../components/TestForms/DHSV.vue"),
    valveform: () => import("../components/TestForms/Valves.vue"),
    downform: () => import("../components/TestForms/Downhole.vue"),
    givenform: () => import("../components/GivenAction.vue"),
  },
  data: () => ({
    given: [],
    givendialog: false,
    given_action: [],
    apm: [],
    listBO: [],
    test_dhsv: [],
    valvesXT: [],
    valvesWH: [],
    wellstypes: [],
    downs: [],
    menuList: [
      { id: 1, label: "Map", checked: false },
      { id: 2, label: "Corrosion", checked: false },
      { id: 3, label: "Cement", checked: false },
      { id: 4, label: "APM", checked: false },
      { id: 5, label: "BO", checked: false },
      { id: 6, label: "DHSV", checked: false },
      { id: 7, label: "Xtree", checked: false },
      { id: 8, label: "Wellhead", checked: false },
      { id: 9, label: "Downhole", checked: false },
    ],
    isMap: false,
    isCorrosion: false,
    isCement: false,
    isAPM: false,
    isBO: false,
    isDHSV: false,
    center: { lat: 34.4, lng: 2.78 },
    zoom_map: 6,
    selected_well: {},
    showWait: false,
    zoom: 1.2,
    dialogform: false,
    ksvg1: 2000,
    barriers: [],
    failures_cmp_list: [],
    well_svg: {},
    components_list: [],
    components_other: [],
    wellsformations: [],
    components_list_details: [],
    corrosion_barriers: [],
    cement_barriers: [],
    tubulars_list: [],
    failures_list: [],
    risks_list: [],
    wmf_list: [],
    failures: [],
    failures_multiple: [],
    failures_report: [],
    failure: {},
    kfailure: 10000,
    kfailure_b: 20000,
    kfailure_a: 30000,
    kga: 6000,
    kcorrosion: 100,
    kcement: 5000,
    cementheaders: [
      {
        text: "Id",
        value: "id",
      },

      {
        text: "Depth From",
        value: "depthfrom",
        selected: true,
      },
      {
        text: "Depth To",
        value: "depthto",
        selected: true,
      },
      {
        text: "Evaluation",
        value: "level",
        selected: true,
      },
    ],
    corrosionheaders: [
      {
        text: "Id",
        value: "id",
      },

      {
        text: "Depth From",
        value: "depthfrom",
        selected: true,
      },
      {
        text: "Depth To",
        value: "depthto",
        selected: true,
      },
      {
        text: "Evaluation",
        value: "evaluation",
        selected: true,
      },
      {
        text: "Level",
        value: "level",
        selected: true,
      },
    ],
    headers: [
      {
        text: "Waiver",
        slot: "col_btn1",
        selected: true,
        icon: "pen-cil",
        color: "indigo",
        width: "10",
        valeur: 0,
        sortable: false,
      },
      {
        text: "Item",
        value: "issue",
        selected: true,
      },
      {
        text: "Element",
        value: "item",
        selected: true,
      },
      {
        text: "Failure rate(%)",
        value: "failure_taux",
        selected: true,
      },
      {
        text: "Risk Ranking",
        value: "risk",
        selected: true,
      },
      {
        text: "Measure Action",
        value: "action",
        selected: true,
      },
      {
        text: "Barrier",
        value: "barrier",
        selected: false,
      },
      {
        text: "Repair Date",
        value: "repair_date",
        selected: true,
      },
      {
        text: "Code Action",
        value: "code_given_action",
        selected: true,
      },
      {
        text: "Given action",
        value: "given_action",
        selected: true,
      },
    ],

    well: {},
    kmap: 1200,
  }),
  async created() {
    if (this.$route.params) {
      this.selected_well = this.$route.params.well;
      this.center = {
        lat: this.selected_well.latitude,
        lng: this.selected_well.longitude,
      };
      this.getdata(parseInt(this.selected_well.id));
    }
  },
  computed: {
    Qfailure_create() {
      return CREATE_TEST_FAILURE;
    },

    Qfailure_delete() {
      return DELETE_TEST_FAILURE;
    },
    selected() {
      return this.menuList.filter((e) => e.checked).map((e) => e.id);
    },
  },
  methods: {
    close_given(item) {
      if (item) {
        this.getdata(parseInt(this.selected_well.id));
      }
      this.givendialog = false;
    },
    async FailureOpen(item) {
      this.failure = item;
      await this.getGivenAction();
      this.kga++;
      this.givendialog = true;
    },
    FailureSelect(item) {
      this.failure = item;
    },
    async getdata(id) {
      this.showWait = true;
      let r = await this.$requette(
        WELL,
        {
          TypeScope: id,
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.allwellsstates.length > 0)
          this.well = r.data.allwellsstates[0];
        this.components_list = r.data.allwellsstates[0].wellscomponents;
        this.components_list_details =
          r.data.allwellsstates[0].wellscomponents_details;
        this.tubulars_list = r.data.allwellsstates[0].wellstubulars;
        this.wellsformations = r.data.allwellsstates[0].wellsformations;
      }
      let r3 = await this.$requette(
        CMP_BARRIERS,
        {
          WellID: id,
        },
        "no-cache"
      );
      if (r3.ok) {
        //
        this.apm = r3.data.test_qapm;
        this.listBO = r3.data.test_qbo;
        this.test_dhsv = r3.data.test_qdhsv;
        this.valvesXT = r3.data.test_qvalvesXT;
        this.valvesWH = r3.data.test_qvalvesWH;
        this.downs = r3.data.test_qdownhead;
        this.components_other = r3.data.components_list;
        this.failures_list = r3.data.components_type_list;
        this.risks_list = r3.data.risks_list;
        this.wmf_list = r3.data.wmf_list;
        this.failures = r3.data.test_failures_single;
        // this.failures_multiple = r3.data.test_failures_multiple;
        // this.failures_report = r3.data.test_failures_all;
        this.failures_cmp_list = r3.data.failures_cmp_list;
        this.corrosion_barriers = r3.data.test_corrosions_list;
        this.cement_barriers = r3.data.test_cements_list;
        this.wellstypes = r3.data.wellstypes;
        this.given_action = r3.data.given_action;
        this.kfailure++;
        this.kfailure_b++;
        this.kfailure_a++;
        this.kcorrosion++;
        this.kcement++;
      }

      this.get_svg();
      this.showWait = false;
    },
    get_svg() {
      this.well_svg = {};
      //treecap
      let treecap = this.components_list.findIndex((x) => x.code == "4");
      if (treecap > 0)
        this.well_svg.gauge = {
          id: this.components_list[treecap].id,
          code: "4",
          label: "TreeCap",
        };

      //addxtree_top

      let xtree_top = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter(
          (x) => x.group_id == 1 && x.orientation == "T" && x.code != "4"
        );
      if (this.components_list.length > 0 || xtree_top.legth > 0)
        this.well_svg.Manifold = { id: 2000, code: "200", label: "Manifold" };
      let xtree_left = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == "L");
      let xtree_right = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == "R");
      let xtree = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == null);

      if (xtree_top.length > 0) this.well_svg.xtree_top = xtree_top;
      if (xtree_left.length > 0)
        this.well_svg.xtree_left = { components: xtree_left };
      if (xtree_right.length > 0)
        this.well_svg.xtree_right = { components: xtree_right };
      if (xtree.length > 0) this.well_svg.xtree = xtree;
      let k = this.tubulars_list.findIndex((x) => x.tubular_id == 1);
      if (k >= 0)
        this.well_svg.tubulars = {
          depth: this.tubulars_list[k].depth,
          components: this.components_list.filter((x) => x.group_id == 13),
        };
      this.well_svg.Annulus = [];

      let annulus = this.components_list.filter((x) => x.group_id == 2);
      let casings = this.tubulars_list
        .filter((x) => x.tubular_id != 1 && x.depthfrom == 0)
        .sort((a, b) => a.csg_order - b.csg_order);

      let csg_order = 0;
      if (casings.length > 0) {
        casings.forEach((x) => {
          this.well_svg.Annulus.push({
            csg_order: csg_order + 1,
            depth: x.depth,
            topcement: x.topcement,
            components: annulus.filter((i) => i.csg_order == csg_order + 1),
          });
          csg_order++;
        });
      }
      //CSG
      let csgs = this.components_list.filter((x) => x.group_id == 9);
      if (csgs.length > 0) this.well_svg.csgs = csgs;
      //tubing 30
      let t30 = this.components_list.findIndex((x) => x.code == "30");
      if (t30 >= 0)
        this.well_svg.TUBING30 = {
          id: this.components_list[t30].id,
          code: "30",
          label: "Tubing",
        };
      //tubing 31
      let t31 = this.components_list.findIndex((x) => x.code == "31");
      if (t31 >= 0)
        this.well_svg.TUBING31 = {
          id: this.components_list[t31].id,
          code: "31",
          label: "Tubing",
        };
      //DHSV
      let t32 = this.components_list.findIndex((x) => x.code == "52");
      if (t32 >= 0)
        this.well_svg.TUBING32 = {
          id: this.components_list[t32].id,
          code: "52",
          label: "DHSV",
        };
      //corrosion
      this.well_svg.corrosion_barriers = this.corrosion_barriers;
      //cement
      this.well_svg.cement_barriers = this.cement_barriers;
      //formations
      this.well_svg.formations = this.wellsformations;
      this.dialogform = true;
      this.ksvg1++;
    },
    async add_failure(item) {
      if (item) {
        this.showWait = true;
        var x = ";" + String(item.cmp_code) + ";";
        var chemin = "";
        let items = [];

        let brs = this.wmf_list.filter((item) => {
          return item.chemin.match(x);
        });
        let fls = this.failures
          .filter((f) => f.cmp_code != "0")
          .filter((item) => {
            return item.barrier.match(x);
          });
        if (fls.length > 0) {
          fls.forEach((element) => {
            if (items.findIndex((i) => i == element.barrier) < 0)
              items.push(element.barrier);
          });
        }
        if (brs.length > 0) {
          //  let index = 10000;
          brs.forEach((element) => {
            // let i = element.chemin.indexOf(x);
            // if (i >= 0 && i < index) {
            //   chemin = element.chemin;
            //   index = i;
            // }
            items.push(element.chemin);
          });
        }
        if (chemin != "") {
          if (items.findIndex((i) => i == chemin) < 0) items.push(chemin);
        }

        let v = this.calculate_barrier_taux(item, items);

        let r;
        let k = this.failures.findIndex((i) => i.cmp_code == item.cmp_code);
        if (k >= 0) {
          v.id = this.failures[k].id;
          let wf = { testfailures: v };
          r = await this.$maj(UPDATE_TEST_FAILURE, wf);
        } else {
          let wf = { testfailures: v };
          r = await this.$maj(CREATE_TEST_FAILURE, wf);
        }
        if (r.ok) {
          let r3 = await this.$requette(CMP_BARRIERS, {
            WellID: parseInt(this.well.id),
          });
          if (r3.ok) {
            this.failures_list = r3.data.components_type_list;
            this.risks_list = r3.data.risks_list;
            this.failures = r3.data.test_failures_single;
            // this.failures_multiple = r3.data.test_failures_multiple;
            // this.failures_report = r3.data.test_failures_all;
            this.kfailure++;
            this.kfailure_b++;
            this.kfailure_a++;
          }
        }
        this.showWait = false;
        this.ksvg1++;
      }
    },
    calculate_barrier_taux(item, items) {
      let barrier = "";
      let t_barrier = 100;
      let t_failure = 100;
      items.forEach((i) => {
        let brs = i.split(";");
        let ord_value = 1;
        let ord_f_total = 0;
        let ord_b_total = 0;
        let ord = 0;
        let val = 0;
        let external = -1;
        let taux_barrier = 0;
        let taux_failure = 0;
        brs.forEach((b) => {
          if (b != "") {
            let fls = this.failures.filter((x) => x.cmp_code == b);
            let k = fls.findIndex((f) => f.cmp_code == b);
            if (ord_value == 1) ord = 3;
            else if (ord_value == 2) ord = 2;
            else if (ord_value == 3) ord = 1;
            else ord = 0.5;
            ord_value++;
            ord_b_total = ord_b_total + ord;
            if (external < 0) {
              ord_f_total = ord_f_total + ord;
              if (b == item.cmp_code) {
                external = item.external;
                taux_failure = taux_failure + ord * item.failure_coef;
              } else taux_failure = taux_failure + ord * 100;
            }
            if (b == item.cmp_code) val = item.failure_coef;
            else if (k >= 0) {
              val = fls[k].failure_coef;
            } else val = 100;
            taux_barrier = taux_barrier + ord * val;
          }
        });
        if (ord_b_total > 0)
          taux_barrier = Math.round(taux_barrier / ord_b_total); //ceil
        if (ord_f_total > 0)
          taux_failure = Math.round(taux_failure / ord_f_total);
        if (taux_barrier <= t_barrier) {
          barrier = i;
          t_barrier = taux_barrier;
          t_failure = taux_failure;
        }
      });
      return {
        cmp_id: item.cmp_id,
        cmp_code: item.cmp_code,
        failure_id: item.failure_id,
        formation_id: item.formation_id,
        barrier_taux: t_barrier,
        failure_taux: t_failure,
        failure_coef: item.failure_coef,
        barrier: barrier,
        well_id: this.selected_well.id,
      };
    },

    downloadsvg() {
      // const element = document.getElementById("svg");
      // const filename = this.selected_well.wellname + "_failures.svg";
      // saveSvgAsPng.saveSvg(element, filename);
    },
    async getGivenAction() {
      let r = await this.$requette(
        GIVEN_ACTION,
        {
          failures: this.failure.item,
        },
        "no-cache"
      );
      if (r.ok) {
        this.given = r.data.getGivenAction;
      }
    },
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
  height: 400px;
  width: 100%;
  min-height: 100%;
  max-height: none;
}
.vue-map-container .gm-ui-hover-effect {
  display: none !important;
}
</style>
