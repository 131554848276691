<template>
  <listitemspages
    :selitem="selitem"
    :qSelect="Qselect"
    :qSelectall="Qselectall"
    :PageResponse="'wellfailures_pages'"
    :AllResponse="'wellfailures_list'"
    :title="'Failures List'"
    :headers="headers"
    :field="'CODE'"
    :filename="'Failures'"
    :sheetname="'Failures'"
    :showedit="false"
    :Add="false"
    :del="false"
    :list_options="false"
    :key="klist"
    :search_elm="filtre"
  />
</template>
<script>
import WELLFAILURES_pages from "../graphql/Reparation/WELLFAILURES_pages.gql";
import WELLFAILURES_list from "../graphql/Reparation/WELLFAILURES_list.gql";
export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
  },
  data() {
    return {
      klist: 1000,
      selitem: {},
      filtre: null,
      headers: [
        {
          text: "Code",
          value: "code",
          enum: "CODE",
          selected: true,
        },
        {
          text: "Well",
          value: "wellname",
          enum: "WELLNAME",
          selected: true,
        },
        {
          text: "Test",
          value: "testtype",
          enum: "TEST",
          selected: true,
        },
        {
          text: "Date",
          value: "test_date",
          selected: true,
        },
        {
          text: "Failures",
          value: "issue",
          enum: "ISSUE",
          selected: true,
        },
        {
          text: "Element",
          value: "component",
          enum: "COMPONENT",
          selected: true,
        },
        {
          text: "Failure rate(%)",
          value: "failure_taux",
          slot: "progress",
          align: "center",
          color: "color",
          width: 140,
          selected: true,
        },
        {
          text: "Risk",
          value: "risk",
          enum: "RISK",
          selected: true,
        },
        {
          text: "Due date",
          value: "repair_date",
          selected: true,
        },
        {
          text: "Action",
          value: "given_action",
          selected: true,
        },
        {
          text: "Active",
          value: "status",
          slot: "checkbox",
          selected: true,
        },
      ],
    };
  },
  watch: {
    "$store.state.fields": {
      handler() {
        this.klist++;
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },
  mounted() {},
  computed: {
    Qselect() {
      return WELLFAILURES_pages;
    },
    Qselectall() {
      return WELLFAILURES_list;
    },
  },
  methods: {},
};
</script>
