<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="12" sm="10" md="10">
          <v-btn
            color="blue darken-1"
            :disabled="
              disble_btn == 1 ||
              changes != 1 ||
              (test.statut_id == 2 && test.users == null) ||
              (test.statut_id == 3 && test.testdate == null)
            "
            @click="complete"
            v-if="task_exist"
            >{{ task_exist.task_type == 0 ? "Complete" : "Evaluate" }}</v-btn
          >

          <v-layout row justify-center align-center v-if="save_disable">
            <v-progress-circular
              indeterminate
              :size="50"
              :width="7"
              color="purple"
            ></v-progress-circular>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="test_save"
            v-if="
              editer &&
              (test.statut_id == 2 ||
                test.statut_id == 3 ||
                test.statut_id == 4 ||
                test.statut_id == 7)
            "
            :disabled="parseInt(changes) == 1"
          >
            Save
          </v-btn>

          <v-btn color="blue darken-1" @click.stop="close" class="ma-1">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <confirmdialog ref="validation" />
    <TaskClose @completed="refresh" :ref="'task_close'"> </TaskClose>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
//import SAVE_EVENT from "../graphql/WellTest/APM/SaveEvent.gql";
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    TaskClose: () => import("../components/TaskCloseForm.vue"),
  },
  props: {
    test: Object,
    testtype_id: Number,
    founds: Array,
    changes: Number,
    save_disable: Boolean,
    invalid_test_tubulars: Array,
  },

  data() {
    return {
      disble_btn: 0,
      showValidation: false,
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      accept: false,
      valider: false,
      editer: false,
      evaluer: false,
      accept_comment: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
    };
  },
  computed: {
    task_exist() {
      let t = null;
      if (this.test)
        if (this.test.tasks) {
          let l = this.test.tasks.filter((elm) => elm.status_id < 2);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (element.users) {
              let i = element.users.findIndex(
                (elm) =>
                  elm.user_id == this.$store.state.me.id && elm.status_id < 2
              );
              if (i >= 0) {
                t = element.users[i];
                break;
              }
            }
          }
        }
      return t;
    },
  },
  created() {
    this.editer =
      this.$store.state.auth.includes("02003") ||
      this.$store.state.auth.includes("06003") ||
      this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
    this.evaluer =
      this.$store.state.auth.includes("07003") || this.$store.state.isadmin;
  },
  methods: {
    refresh(statut_id) {
      //if (this.test.statut_id == 5) this.$emit("set_failures");
      this.$emit("refresh", statut_id);
    },
    async complete() {
      this.disble_btn = 1;

      if (this.test.statut_id == 2)
        this.$emit("attribute_test", (result) => {
          // Parent component will pass the result of the validation
          if (result) {
            this.$refs.task_close.open(this.task_exist);
          }
        });
      if (this.test.statut_id == 3 || this.test.statut_id == 4)
        this.$emit("validate_test", (result) => {
          // Parent component will pass the result of the validation
          if (result) {
            this.$refs.task_close.open(this.task_exist);
          }
        });
      if (this.test.statut_id == 5 || this.test.statut_id == 7)
        this.$emit("accept_test", (result) => {
          if (result) {
            this.$refs.task_close.open(this.task_exist);
          }
        });
      this.disble_btn = 0;
    },
    close() {
      this.$emit("close");
    },

    test_save() {
      this.$emit("test_save");
    },
  },
};
</script>
