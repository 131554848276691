<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row
            align="baseline"
            dense
            no-gutters
            v-for="(item, i) in given"
            :key="i"
          >
            <v-col dense cols="12" sm="4" md="4">
              <div :style="getStyle(item.welltype_id)">
                {{ item.welltype }}
              </div>
            </v-col>
            <v-col dense cols="12" sm="8" md="8">
              <v-select
                class="rowh0"
                outlined
                dense
                v-model="item.ga_id"
                :items="given_action"
                item-value="id"
                item-text="description"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" color="blue darken-1" @click="save()">
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-form
  >
</template>
<script>
import SaveFailureGA from "../graphql/Tests/SaveFailureGA.gql";
//import UpdateGivenAction from "../graphql/Tests/UpdateGivenAction.gql";
export default {
  components: {},
  props: {
    dialog: Boolean,
    given_action: Array,
    failure: Object,
    given: Array,
    welltype: String,
  },
  data() {
    return {
      kall: 1000,
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    get_value() {},
    getStyle(type) {
      if (type == this.welltype)
        return "color: blue;text-decoration: underline;";
    },
    closeDialog() {
      this.$emit("close_given");
    },
    save() {
      let given = [];
      this.given.forEach((x) => {
        if (x.id > 0 || (x.id < 0 && x.ga_id != 1))
          given.push({
            id: x.id,
            ga_id: x.ga_id,
            welltype_id: x.welltype_id,
            failures: this.failure.barrier,
          });
      });

      this.$apollo
        .query({
          query: SaveFailureGA,
          variables: {
            given: given,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          this.$emit("close_given", 1); //refresh
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.rowh2 {
  width: 100% !important;
  height: 25px !important;
}
</style>
