<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="800"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <!-- Radio Buttons Row -->
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.default_title"
                  label="Default Title"
                  :rules="[(v) => !!v || 'Title required !!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.filter_by"
                  label="Filter"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="task?"
                  v-model="editedItem.istask"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.default_message"
                  label="Default Message"
                  :rules="[(v) => !!v || 'Default Message required !!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.count_message"
                  label="Count Message"
                  :rules="[(v) => !!v || 'Count Message required !!']"
                  v-if="!editedItem.istask"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="editedItem.istask && due_date_rules"
              >
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.due_date"
                  label="Due date (days)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Email"
                  v-model="editedItem.send_by_email"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Whatsapp"
                  v-model="editedItem.send_by_sms"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.resend_after_hours"
                  label="Resend After (hours)"
                  v-if="
                    editedItem.send_by_email == 1 || editedItem.send_by_sms == 1
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" v-if="!editedItem.istask">
                <v-select
                  v-model="editedItem.notif_type"
                  :items="notif_types"
                  item-value="id"
                  item-text="name"
                  label="Notif Type"
                  :rules="[(v) => !!v || 'Type required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4" v-if="editedItem.istask">
                <v-select
                  v-model="editedItem.task_type"
                  :items="task_types"
                  item-value="id"
                  item-text="name"
                  label="Task Type"
                  :rules="[(v) => !!v || 'Type required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4" v-if="editedItem.istask">
                <v-select
                  v-model="editedItem.status_yes"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  label="Next Status"
                  :rules="[(v) => !!v || 'Next Status required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="editedItem.istask && editedItem.task_type == 1"
              >
                <v-select
                  v-model="editedItem.status_no"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  label="Rejected Status"
                  :rules="[(v) => !!v || 'Rejected Status required']"
                  chips
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" sm="4" md="4" v-if="editedItem.istask">
                <v-select
                  v-model="editedItem.complete_mode"
                  :items="modes"
                  item-value="id"
                  item-text="name"
                  label="Complete Mode"
                  :rules="[(v) => !!v || 'Complete Mode required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Active"
                  v-model="editedItem.active"
                  dense
                ></v-checkbox>
              </v-col-->
              <v-col cols="12" sm="4" md="4" lg="4" v-if="editedItem.istask">
                <v-autocomplete
                  v-model="editedItem.task_auth"
                  :items="list_auths"
                  :item-text="'autorisation'"
                  :item-value="'code'"
                  chips
                  label="Task Autorisation"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-autocomplete
                  v-model="editedItem.notif_auth"
                  :items="list_auths"
                  :item-text="'autorisation'"
                  :item-value="'code'"
                  chips
                  label="Notif Autorisation"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_RULE from "@/graphql/Event/CREATE_EVENT_RULE.gql";
import UPDATE_RULE from "@/graphql/Event/UPDATE_EVENT_RULE.gql";
import { autorisations } from "@/utils/functions.js";
export default {
  components: {},
  name: "ruleform",
  props: {
    item: Object,
    items: Array,
    event: Object,
    showForm: Boolean,
    status: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    selected_auths: [],
    list_auths: [],

    task_types: [
      { id: "0", name: "Execution Task" },
      { id: "1", name: "Approval Task " },
    ],
    notif_types: [
      { id: "info", name: "info" },
      { id: "warning", name: "Warning" },
      { id: "critical", name: "Critical" },
      { id: "alert", name: "Alert" },
    ],

    modes: [
      { id: "one", name: "One" },
      { id: "all", name: "All" },
    ],
  }),

  computed: {
    due_date_rules() {
      let ok = false;
      if (this.event)
        ok =
          this.event.model != "Request" ||
          (this.event.model == "Request" && this.editedItem.task_type == 1);
      return ok;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.editedItem)
        return this.editedItem.id < 0
          ? "New " + (this.editedItem.istask ? "Task" : "Notification")
          : (this.editedItem.istask ? "Task : " : "Notification : ").concat(
              this.editedItem.default_title
            );
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.list_auths = autorisations;
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else this.editedItem = Object.assign({}, { id: -1 });
    }
  },

  methods: {
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > 0) {
          let r = await this.maj(UPDATE_RULE, {
            eventapprule: {
              id: this.editedItem.id,
              default_title: this.editedItem.default_title,
              filter_by: this.editedItem.filter_by,
              istask: this.editedItem.istask ? 1 : 0,
              default_message: this.editedItem.default_message,

              count_message: this.editedItem.count_message,
              due_date: this.editedItem.due_date,
              task_type: this.editedItem.task_type,
              status_yes: this.editedItem.status_yes,
              status_no: this.editedItem.status_no,
              complete_mode: this.editedItem.complete_mode,
              task_auth: this.editedItem.task_auth,
              notif_auth: this.editedItem.notif_auth,

              send_by_email: this.editedItem.send_by_email ? 1 : 0,
              send_by_sms: this.editedItem.send_by_sms ? 1 : 0,
              resend_after_hours: this.editedItem.resend_after_hours,
              write_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.splice(this.item.index, 1, this.editedItem);
          }
        } else {
          let r = await this.maj(CREATE_RULE, {
            eventapprule: {
              event_app_id: this.event.id,
              default_title: this.editedItem.default_title,
              filter_by: this.editedItem.filter_by,
              istask: this.editedItem.istask ? 1 : 0,
              default_message: this.editedItem.default_message,

              count_message: this.editedItem.count_message,
              due_date: this.editedItem.due_date,
              task_type: this.editedItem.task_type,
              status_yes: this.editedItem.status_yes,
              status_no: this.editedItem.status_no,
              complete_mode: this.editedItem.complete_mode,

              task_auth: this.editedItem.task_auth,
              notif_auth: this.editedItem.notif_auth,

              send_by_email: this.editedItem.send_by_email ? 1 : 0,
              send_by_sms: this.editedItem.send_by_sms ? 1 : 0,
              resend_after_hours: this.editedItem.resend_after_hours,
              create_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.editedItem.id = r.createEventAppRule.id;
            this.items.push(this.editedItem);
          }
        }
        this.$emit("changed");
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
