var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[(_vm.task_exist)?_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.disble_btn == 1 ||
            _vm.changes != 1 ||
            (_vm.test.statut_id == 2 && _vm.test.users == null) ||
            (_vm.test.statut_id == 3 && _vm.test.testdate == null)},on:{"click":_vm.complete}},[_vm._v(_vm._s(_vm.task_exist.task_type == 0 ? "Complete" : "Evaluate"))]):_vm._e(),(_vm.save_disable)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(
            _vm.editer &&
            (_vm.test.statut_id == 2 ||
              _vm.test.statut_id == 3 ||
              _vm.test.statut_id == 4 ||
              _vm.test.statut_id == 7)
          )?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"blue darken-1","disabled":parseInt(_vm.changes) == 1},on:{"click":function($event){$event.stopPropagation();return _vm.test_save.apply(null, arguments)}}},[_vm._v(" Save ")]):_vm._e(),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"blue darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Close ")])],1)],1)],1),_c('confirmdialog',{ref:"validation"}),_c('TaskClose',{ref:'task_close',on:{"completed":_vm.refresh}}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }