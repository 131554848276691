<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <listitems
          :title="'Events'"
          :list="events"
          :headers="event_headers"
          :Get_suf="'EventApp'"
          :showedit="false"
          :showstd="false"
          :master="false"
          :add="true"
          :del="false"
          :Update="true"
          :key="kevent"
          :ipg="-1"
          @open="EventOpen"
          @rowselect="Roweventselect"
          :laoding="Loading"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <listitems
              :title="'Tasks/Notifications'"
              :list="event_rules"
              :qDelete="Qruledelete"
              :headers="tasks_headers"
              :Get_suf="'EventAppRule'"
              :showedit="false"
              :showstd="false"
              :master="false"
              :key="krule"
              :add="true"
              :add_disable="event.id != null"
              :del_disable="event_rule.recipient_count > 0"
              :Update="true"
              @delete="krule++"
              @open="RuleOpen"
              @rowselect="RowRuleSelect"
              :laoding="Loading"
            >
            </listitems>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <listitems
              :title="'Recipient'"
              :list="task_recipients"
              :qDelete="Qrecipientdelete"
              :headers="recipient_headers"
              :Get_suf="'EventAppRecipient'"
              :showstd="false"
              :showedit="false"
              :master="false"
              :key="krecip"
              :add="true"
              :add_disable="event_rule.id != null"
              :Update="true"
              @open="RecipientOpen"
              @rowselect="RowRecipientSelect"
              @delete="krecip++"
              :laoding="Loading"
            >
            </listitems>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <recipientform
      :item="recipient"
      :items="tab1 == '1' ? notif_recipients : task_recipients"
      :rule="event_rule"
      :users="user_list"
      :profiles="profiles"
      :showForm="!isRcptClose"
      :key="krcpt"
      @close="isRcptClose = true"
    >
    </recipientform>
    <eventform
      :item="event"
      :showForm="!isEventClose"
      :items="events"
      :key="ke"
      :event_status="event_status"
      @close="isEventClose = true"
    ></eventform>
    <ruleform
      :item="event_rule"
      :showForm="!isRuleClose"
      :items="event_rules"
      :status="status"
      :event="event"
      :key="kr"
      @close="isRuleClose = true"
    ></ruleform>
  </v-container>
</template>

<script>
import DELETE_RULE from "@/graphql/Event/DELETE_EVENT_RULE.gql";
import DELETE_RECIPIENT from "@/graphql/Event/DELETE_EVENT_RECEIPT.gql";

import DATA from "@/graphql/Event/DATA.gql";
export default {
  components: {
    listitems: () => import("../ListItems.vue"),
    recipientform: () => import("./Recipient.vue"),
    eventform: () => import("./EventForm.vue"),
    ruleform: () => import("./RuleForm.vue"),
  },

  data: () => ({
    tab1: "0",
    event_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Name",
        value: "name",
        slot: "href",
        selected: true,
      },
      {
        text: "Description",
        value: "description",
        selected: false,
      },
      {
        text: "Interface",
        value: "interface",
        selected: true,
      },
      {
        text: "Model",
        value: "model",
        selected: true,
      },

      {
        text: "Initial Status",
        value: "status_ini",
        selected: false,
      },

      {
        text: "Active",
        value: "active",
        selected: true,
      },
    ],

    tasks_headers: [
      {
        text: "event_app_id",
        value: "event_app_id",
        default: "",
        edit: true,
        hiden: true,
      },

      {
        text: "Default Title",
        value: "default_title",
        selected: true,
        slot: "href",
      },
      {
        text: "Filter",
        value: "filter_by",
        selected: false,
      },
      {
        text: "task?",
        value: "istask",
        selected: true,
        slot: "checkbox",
      },

      {
        text: "Default Message",
        value: "default_message",
        selected: true,
      },
      {
        text: "Count Message",
        value: "count_message",
        selected: false,
      },
      {
        text: "Due date (days)",
        value: "due_date",
        selected: false,
      },
      {
        text: "Task Type",
        value: "task_type",
        selected: false,
      },
      {
        text: "Next Status",
        value: "status_yes",
        selected: false,
      },
      {
        text: "Rejected Status",
        value: "status_no",
        selected: false,
      },
      {
        text: "Complete Mode",
        value: "complete_mode",
        selected: false,
      },
      {
        text: "Email",
        value: "send_by_email",
        selected: true,
        slot: "checkbox",
      },
      {
        text: "Whatsapp",
        value: "send_by_sms",
        selected: true,
        slot: "checkbox",
      },
      {
        text: "Resend After (hours)",
        value: "resend_after_hours",
        selected: false,
      },
    ],

    recipient_headers: [
      {
        text: "event_rule_id",
        value: "event_rule_id",
        default: "",
        edit: true,
        hiden: true,
      },

      {
        text: "Recipient",
        value: "recipient_name",
        selected: true,
        slot: "href",
      },
      {
        text: "Type",
        value: "recipient_type",
        selected: true,
        edit: true,
        rules: [(v) => !!v || "Type required"],
        type: "Number",
        sm: 6,
        md: 6,
      },
      {
        text: "Notify Only",
        value: "notify_only",
        selected: true,
        slot: "checkbox",
      },
      {
        text: "Email",
        value: "send_by_email",
        selected: true,
        slot: "checkbox",
      },
      {
        text: "Whatsapp",
        value: "send_by_sms",
        selected: true,
        slot: "checkbox",
      },
    ],
    events: [],
    event_rules: [],
    event: {},
    event_rule: {},
    kevent: 15470,
    krule: 15580,
    krecip: 16580,
    notif_recipients: [],
    task_recipients: [],
    recipient: {},
    isRcptClose: true,
    isEventClose: true,
    isRuleClose: true,
    Loading: false,
    user_list: [],

    krcpt: 17580,
    ke: 18580,
    kr: 19225,
    profiles: [],
    event_status: [
      {
        id: "Request",
        status: [
          { id: "0", name: "Pending" },
          { id: "1", name: "In progress" },
          { id: "2", name: "Completed" },
          { id: "3", name: "Rejected" },
          { id: "4", name: "Accepted" },
          { id: "5", name: "Canceled" },
        ],
      },
      {
        id: "Well",
        status: [
          { id: "1", name: "Pending" },
          { id: "2", name: "Valid" },
        ],
      },
      {
        id: "TestsPlanning",
        status: [
          { id: "0", name: "Draft" },
          { id: "1", name: "Accepted" },
          { id: "2", name: "Valid" },
          { id: "3", name: "Completed" },
        ],
      },
      {
        id: "Welltest",
        status: [
          { id: "0", name: "Draft" },
          { id: "1", name: "Planned" },
          { id: "2", name: "Assigned" },
          { id: "3", name: "Attributed" },
          { id: "4", name: "In Progress" },
          { id: "5", name: "Completed" },
          { id: "6", name: "Accepted" },
          { id: "7", name: "Rejected" },
          { id: "8", name: "Canceled" },
        ],
      },
      {
        id: "Reparation",
        status: [
          { id: "1", name: "Planned" },
          { id: "2", name: "Assigned" },
          { id: "3", name: "Attributed" },
          { id: "4", name: "In Progress" },
          { id: "5", name: "Completed" },
          { id: "6", name: "Accepted" },
          { id: "7", name: "Rejected" },
          { id: "8", name: "Canceled" },
        ],
      },
      {
        id: "TestAPM",
        status: [
          { id: "0", name: "Normal" },
          { id: "1", name: "MAASP Exceed" },
          { id: "2", name: "Max Exceed" },
          { id: "3", name: "Min Below" },
        ],
      },
      {
        id: "TestFailures",
        status: [
          { id: "1", name: "Pending repair" },
          { id: "2", name: "Repaired" },
        ],
      },
    ],
  }),

  computed: {
    status() {
      let l;
      if (this.event)
        if (this.event.model) {
          let i = this.event_status.findIndex((s) => s.id == this.event.model);
          if (i >= 0) l = this.event_status[i].status;
        }
      return l;
    },
    Qruledelete() {
      return DELETE_RULE;
    },
    Qrecipientdelete() {
      return DELETE_RECIPIENT;
    },
  },
  watch: {},
  created() {},

  async mounted() {
    let r = await this.requette(DATA);
    if (r) {
      this.events = r.eventsApp;
      this.user_list = r.users;

      this.profiles = r.profiles;
      this.kevent++;
    }
  },

  methods: {
    tabChange() {
      this.event_rule = {};
      this.notif_recipients = [];
      this.task_recipients = [];
      this.krule++;
      this.krecip++;
    },
    Roweventselect(item) {
      this.event = item;
      this.event_rules = item.rules;
      this.tasks_headers[0].default = item.id;

      this.event_rule = {};
      this.notif_recipients = [];
      this.task_recipients = [];
      this.krule++;
      this.krecip++;
    },
    RowRecipientSelect(item) {
      this.recipient = item;
    },
    RecipientOpen(item) {
      this.recipient = item;
      this.isRcptClose = false;
      this.krcpt++;
    },
    EventOpen(item) {
      this.event = item;
      this.isEventClose = false;
      this.ke++;
    },
    RuleOpen(item) {
      this.event_rule = item;
      this.isRuleClose = false;
      this.kr++;
    },
    RowNotifSelect(item) {
      this.event_rule = item;
      this.notif_recipients = item.recipients;
      this.krecip++;
      this.recipient_headers[0].default = item.id;
    },
    RowRuleSelect(item) {
      this.event_rule = item;
      this.task_recipients = item.recipients;
      this.krecip++;
      this.recipient_headers[0].default = item.id;
    },
    async requette(query, v, f) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    DepotChange(item) {
      this.depot = item;
    },
    OpenDepotForm(item) {
      this.aff3++;
      this.depot = item;
      this.isDepotClosed = false;
    },
    closeDepotForm() {
      this.aff1++;
      this.isDepotClosed = true;
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
  },
};
</script>

<style></style>
