<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          &nbsp;&nbsp;
          <v-row dense class="rowh2">
            <v-col dense cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.hyd_oil"
                @change="get_failures()"
                @input="changes"
                hide-spin-buttons
                label="Hydraulic oil return vol"
              >
              </v-text-field>
            </v-col>
            <v-col dense cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.o_time"
                @change="get_failures()"
                @input="changes"
                hide-spin-buttons
                label="Closing Time"
              >
              </v-text-field>
            </v-col>
            <v-col dense cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.o_pressure"
                @change="get_failures()"
                @input="changes"
                hide-spin-buttons
                label="Opening Time"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <br />
          &nbsp;&nbsp; Leak off test
          <v-row dense class="rowh2">
            <v-col dense cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.i_pressure"
                @input="changes"
                hide-spin-buttons
                :label="'Initial pressure (' + pressure_unit + ')'"
                @change="get_failures()"
              >
              </v-text-field>
            </v-col>
            <v-col dense cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.f_pressure"
                @input="changes"
                hide-spin-buttons
                :label="'Final pressure (' + pressure_unit + ')'"
                @change="get_failures()"
              >
              </v-text-field>
            </v-col>
            <v-col dense cols="12" sm="3" md="2">
              <v-text-field
                autocomplete="off"
                class="rowh0"
                type="number"
                outlined
                dense
                v-model="test_dhsv.time"
                hide-spin-buttons
                @input="changes"
                label="time"
                @change="get_failures()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    testdhsv: Object,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
    barriers: Array,
    all_failures_list: Array,
  },
  data() {
    return {
      test_dhsv: {},
      test_failures: [],
      well_failures: [],
      pressure_unit: "",
      t_changes: 0,
    };
  },

  mounted() {
    this.test_dhsv = Object.assign({}, this.testdhsv);
    this.pressure_unit = this.$store.state.options[0].pressure;
    this.well_failures = Object.assign([], this.failures_list);
    this.$emit("changes", this.test_dhsv, this.failures_list, this.t_changes);
  },
  computed: {},
  watch: {},
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    set_failures() {
      this.get_failures();
      return this.test_failures;
    },
    close_change() {
      if (this.test_dhsv.hyd_oil && this.test_dhsv.Hydraulic_oil_return) {
        if (this.test_dhsv.hyd_oil > this.Hydraulic_oil_return)
          this.set_falure(this.test_dhsv, 264, 2); //failed to close

        if (this.test_dhsv.opening_pressure > this.opening_pressure)
          this.set_falure(this.test_dhsv, 290, 2); //failed to open
      }
      this.t_changes++;
    },
    pressure_change() {
      if (this.test_dhsv.i_pressure == 0) this.test_dhsv.i_pressure = null;
      if (this.test_dhsv.f_pressure == 0) this.test_dhsv.f_pressure = null;
      if (this.test_dhsv.i_pressure && this.test_dhsv.f_pressure) {
        if (
          Math.abs(this.test_dhsv.i_pressure - this.test_dhsv.f_pressure) >=
          (this.test_dhsv.i_pressure * 3) / 100
        )
          this.set_falure(this.test_dhsv, 212, 1);
        //Leak > Limit
        else this.set_falure(this.test_dhsv, 214, 1); //Leak < Limit
      }
      this.t_changes++;
    },
    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        state: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        well_tubular_id: item.well_tubular_id,
        g_id: gid,
        testtype: this.editedItem.testtype_id,
      };

      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );

      //this.$calc_single(this.barriers, this.failures_list, fail);
    },
    get_failures() {
      this.test_failures = [];
      // get actual failures
      this.close_change();
      this.pressure_change();
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter((x) => x.cmp_code != "0" && x.failure_id > 0),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );

      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      //  if (multiples) multiples.forEach((m) => this.test_failures.push(m));
      this.$emit("changes", this.test_dhsv, this.test_failures, this.t_changes);
    },

    changes() {
      this.$emit("changes", this.test_dhsv, this.failures_list, this.t_changes);
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
