<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title
          >{{ "Focal point attribution: " + list.length + " wells" }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <br />
              <v-select
                :items="users_attr"
                item-value="id"
                item-text="name"
                label="Operator"
                outlined
                dense
                return-object
                @change="operator_change"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-data-table
                height="420"
                fixed-header
                v-model="selected"
                :headers="headers"
                :items="list"
                :single-select="false"
                hide-default-footer
                disable-pagination
                item-key="id"
                show-select
                class="elevation-1"
                :key="k1"
              >
              </v-data-table>
            </v-col>
            <v-col cols="1">
              <div class="outer-div">
                <div class="middle-div">
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    class="ma-1"
                    :disabled="!is_selected1"
                    @click.stop="addAttr()"
                    >Add</v-btn
                  >
                </div>
                <div class="middle-div">
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    class="ma-1"
                    @click.stop="delAttr()"
                    :disabled="!is_selected2"
                    v-if="att_fp"
                    >Del</v-btn
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="7">
              {{ "Attributed wells:" }}
              <v-data-table
                height="400"
                fixed-header
                v-model="selected2"
                :headers="headers"
                :items="tests_attr"
                :single-select="false"
                hide-default-footer
                disable-pagination
                item-key="id"
                show-select
                class="elevation-1"
                :key="k2"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save"
            v-if="change > 0 && att_fp"
          >
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      <waiter :showWait="showWait"> </waiter>
    </v-dialog>
  </v-form>
</template>
<script>
import TestAssignment from "../graphql/Planning/TestAssignment.gql";
export default {
  components: {
    waiter: () => import("../components/Widgets/Waiter.vue"),
  },

  props: {
    dialog: Boolean,
    tests: Array,
    users: Array,
    plan: Object,
    att_fp: Boolean,
    att_ex: Boolean,
  },
  data() {
    return {
      list: [],
      tests_list: [],
      tests_attr: [],
      showWait: false,
      operator_id: null,
      change: "0",
      operator_name: null,
      selected: [],
      selected2: [],
      k1: 1000,
      k2: 2000,

      headers: [
        {
          text: "Well",
          value: "wellname",
          selected: true,
        },
        {
          text: "Test",
          value: "description",
          selected: true,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.tests_attr = [];
    this.list = [];
    this.headers[1].selected = false;
    this.headers = this.headers.filter((x) => x.selected == true);
    this.tests_list = this.tests.filter((x) => x.description != "Repair");

    // this.list = this.all_tests();
    this.tests_attr = this.attr_tests();
    this.list = this.all_tests().filter(
      (x) => this.attr_tests().findIndex((w) => w.well_id == x.well_id) < 0
    );
    this.selected = [];
    this.selected2 = [];
    this.k1++;
    this.k2++;
  },
  computed: {
    is_selected1() {
      return this.selected.length > 0 && this.operator_id;
    },
    is_selected2() {
      return this.selected2.length > 0 && this.operator_id;
    },
    users_attr() {
      let l = [];
      l = this.users.filter((elm) => elm.autorisations.includes("04003"));
      return l;
    },
    users_exe() {
      let l = [];
      l = this.users.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
  },
  methods: {
    all_tests() {
      return [
        ...new Map(
          this.tests_list
            .filter((x) => !x.owners)
            .map((item) => [item["well_id"], item])
        ).values(),
      ];
    },
    attr_tests() {
      return [
        ...new Map(
          this.tests_list
            .filter((x) => x.owners && x.owners == this.operator_id)
            .map((item) => [item["well_id"], item])
        ).values(),
      ];
    },

    operator_change(item) {
      this.operator_id = item.id;
      this.operator_name = item.nom;
      this.tests_attr = this.attr_tests();
      this.k1++;
      this.k2++;
    },
    selected_attr(items) {
      this.selected = items;
    },
    unselect_attr(items) {
      this.selected2 = items;
    },
    addAttr() {
      let sel = this.selected;

      sel.forEach((x) => {
        x.owners = this.operator_id;
      });
      this.tests_attr = this.attr_tests();
      this.list = this.all_tests().filter(
        (x) => this.attr_tests().findIndex((w) => w.well_id == x.well_id) < 0
      );
      this.change++;
      this.selected = [];
      this.selected2 = [];
      this.k1++;
      this.k2++;
    },
    delAttr() {
      this.selected2.forEach((x) => {
        let k = this.tests_list.findIndex((y) => y.id == x.id);

        this.tests_list[k].create_uid = null;
      });
      this.tests_attr = this.attr_tests();
      this.list = this.all_tests().filter(
        (x) => this.attr_tests().findIndex((w) => w.well_id == x.well_id) < 0
      );
      this.change++;
      this.selected = [];
      this.selected2 = [];
      this.k1++;
      this.k2++;
      this.k2++;
    },
    async save() {
      this.showWait = true;

      let v = [];

      this.tests_attr.forEach((x) => {
        v.push({
          id: x.id,
          create_uid: x.owners,
          well_id: x.well_id,
          planning_id: this.plan.id,
        });
      });

      this.$apollo
        .query({
          query: TestAssignment,
          variables: {
            tests: v,
            plannig_id: this.plan.id,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          this.$store.dispatch("Changed", true);
          this.closeDialog(1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.change = 0;

      this.showWait = false;
    },
    closeDialog(item) {
      this.$emit("close_attr", item);
    },
  },
};
</script>
<style scoped>
div.middle-div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 4px;
}
div.outer-div {
  height: 90%;
}
</style>
