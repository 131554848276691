<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    persistent
    transition="dialog-top-transition"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        {{ test.wellname }}:&nbsp;{{ test.description }}</v-card-title
      >
      <v-card-text>
        <listitems
          :title="'Waivers List'"
          :list="waivers_list"
          :headers="event_headers"
          :showstd="false"
          :showedit="false"
          :master="false"
          :add="
            can_edit &&
            waivers_list.findIndex(
              (x) =>!x.is_null
            ) < 0
          "
          :del="true"
          :btn_update="true"
          :del_disable="
            !can_edit ||
            !(
              !waiver.is_null &&
              waiver.operator_id == this.$store.state.me.id  
            )
          "
          :update_desa="
            !can_edit ||
            !(
              waiver.is_null &&
              waiver.operator_id == this.$store.state.me.id  
            )
          "
          :key="cor_key2"
          @open="WaiverOpen"
          @btn_update_click="WaiverUpdate"
          @delete="WaiverOpen"
          @rowselect="WaiverSelect"
          @col_btn1_click="WaiverSet_State"
        >
        </listitems>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-1" color="blue darken-1" @click="close_dialog()">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <waiverform
      :dialog="waiver_dialog"
      :waiver="waiver"
      :edit="true"
      :key="k_waiver"
      @WaiverClose="WaiverClose" 
    ></waiverform>
    <confirmdialog ref="confirm" />
    <confirmdialog ref="accept" />
  </v-dialog>
</template>
<script>
import EventUpdate from "../graphql/Planning/EventUpdate.gql";
import TestsWaivers from "../graphql/Planning/TestWaivers.gql";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
    waiverform: () => import("../components/Waiver.vue"),
  },
  props: {
    dialog: Boolean,
    waivers: Array,
    plan: Object,
    test: Object,
 
    can_edit: { type: Boolean, default: false },
    can_accept: { type: Boolean, default: false },
    can_reject: { type: Boolean, default: false },
  },
  data() {
    return { 
      add: false,
      waiver_dialog: false,
      cor_key2: 200,
      k_waiver: 300,
      waivers_list: [],
      waiver: {},
      event_headers: [
        {
          text: "Profile",
          value: "profile",
          selected: true,
          slot:"href"
        },
        {
          text: "User",
          value: "username",
          selected: true,
        },
        {
          text: "Waiver",
          value: "event_date",
          selected: true,
        },
        {
          text: "Coment",
          value: "comment",
          selected: true,
        },
        {
          text: "status",
          value: "statut",
          selected: true,
        },
        {
          text: "Accept",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-check",
          width: "10",
          valeur: true,
          sortable: false,
          disabled: "is_null",
        },
        {
          text: "Reject",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-check",
          width: "10",
          valeur: false,
          sortable: false,
          disabled: "is_null",
        },
      ],
    };
  },
  mounted() {
    this.waivers_list = Object.assign([], this.waivers);
    if (this.waivers_list.length > 0) this.waiver = this.waivers_list[0];
    this.event_headers[5].selected = this.can_accept;
    this.event_headers[6].selected = this.can_reject;
    this.cor_key2++;
  
  },
  computed: {
    owner() {
      return true;
    },
  },
  methods: {
    WaiverSelect(item) {
      this.waiver = item;
    },
    WaiverSet_State(item) {
      this.stateEvent(item.key_valeur);
    },
    WaiverUpdate() {
     
      this.waiver_dialog = true;
      this.k_waiver++;
    },
    WaiverOpen(item) {
      this.waiver=item;
      this.waiver.planning_id=this.plan.id;
      this.waiver.test_id=this.test.id;
       this.waiver.statut=item.statut?item.statut:"Task";
    //  this.waiver.event_statut=item.event_statut?item.event_statut:1;
      // this.waiver = {
      //   planning_id: this.plan.id,
      //   test_id: this.test.id,
      // }; 
      this.waiver_dialog = true;
      this.k_waiver++;
    },
    WaiverClose(item) {
      if (item) {
        this.refrech();
        this.$emit("close_waiver", this.plan.id);
      }
      this.waiver_dialog = false;
    },
    close_dialog(operation) {
      this.$emit("close_waiver");
      if (operation) {
        this.refrech();
      }
    },
    async stateEvent(state) {
      if (
        await this.$refs.accept.open(
          "Confirmation",
          state ==true ? "Accept current waiver" : "Reject  current waiver",
          { color: "orange darken-3" }
        )
      ) {
        let v = {
          id: this.waiver.id,
          accept: state,
        };
        this.$apollo
          .query({
            query: EventUpdate,
            variables: {
              updatetestevent: v,
            },
            fetchPolicy: "no-cache",
          })
          .then(() => { 
            this.waiver.accept = true;
            this.waiver.is_accepted = state == true ;
            this.waiver.is_rejected = state == false ;
            this.waiver.is_null = true;
            this.waiver.statut = state == true ? "Accepted" : "Rejected";
            this.cor_key2++;
            this.$emit("close_waiver", this.plan.id);
          })
          .catch(() => {});
      }
    },
    async refrech() {
      this.showWait = true;
      let r = await this.$requette(
        TestsWaivers,
        {
          test_id: this.test.id,
          plan_id: this.plan.id,
          user_id: this.$store.state.me.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.waivers_list = r.data.tests_events;
        this.cor_key2++;
      }
      this.showWait = false;
    },

  },
};
</script>
