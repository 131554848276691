<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="800"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Notify Only"
              v-model="editedItem.notify_only"
              dense
              v-if="rule.istask == 1"
            ></v-checkbox>
          </v-card-title>

          <v-card-text>
            <!-- Radio Buttons Row -->
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.recipient_type"
                  :items="[
                    { id: 'profile', name: 'Profile' },
                    { id: 'user', name: 'User' },
                    { id: 'users', name: 'Executions' },
                    { id: 'owners', name: 'Owners' },
                  ]"
                  label="Type"
                  :rules="[(v) => !!v || 'Type required']"
                  item-value="id"
                  item-text="name"
                  @change="editedItem.recipient_id = null"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="editedItem.recipient_type === 'user'"
              >
                <v-select
                  v-model="editedItem.recipient_id"
                  :items="users_list"
                  label="User"
                  :rules="[(v) => !!v || 'User required']"
                  item-value="id"
                  item-text="name"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="editedItem.recipient_type === 'profile'"
              >
                <v-select
                  v-model="editedItem.recipient_id"
                  :items="profiles_list"
                  item-value="id"
                  item-text="description"
                  label="Profile"
                  :rules="[(v) => !!v || 'Profile required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="editedItem.recipient_type != 'user'"
              >
                <v-select
                  v-model="editedItem.complete_mode"
                  :items="modes"
                  item-value="id"
                  item-text="name"
                  label="Complete Mode"
                  :rules="[(v) => !!v || 'Complete Mode required']"
                  chips
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Email"
                  v-model="editedItem.send_by_email"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Whatsapp"
                  v-model="editedItem.send_by_sms"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_RECIPIENT from "@/graphql/Event/CREATE_EVENT_RECEIPT.gql";
import UPDATE_RECIPIENT from "@/graphql/Event/UPDATE_EVENT_RECEIPT.gql";

export default {
  components: {},
  name: "recipientform",
  props: {
    item: Object,
    items: Array,
    rule: Object,
    showForm: Boolean,
    users: Array,
    profiles: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    modes: [
      { id: "one", name: "One" },
      { id: "all", name: "All" },
    ],
  }),

  computed: {
    profiles_list() {
      let l = this.profiles;
      if (this.rule) {
        let a =
          !this.rule.istask || this.editedItem.notify_only
            ? this.rule.notif_auth
            : this.rule.task_auth;
        if (a) {
          l = [];
          this.profiles.forEach((profile) => {
            if (profile.autorisations)
              if (profile.autorisations.includes(a)) {
                l.push(profile);
              }
          });
        }
      }
      return l;
    },
    users_list() {
      let l = this.users;
      if (this.rule) {
        let a =
          !this.rule.istask || this.editedItem.notify_only
            ? this.rule.notif_auth
            : this.rule.task_auth;
        if (a) {
          l = [];
          this.users.forEach((user) => {
            if (user.autorisations)
              if (user.autorisations.includes(a)) {
                l.push(user);
              }
          });
        }
      }
      return l;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "New Recipient "
          : "Recipient : ".concat(this.item.recipient_name);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) this.editedItem = Object.assign({}, this.item);
      else
        this.editedItem = Object.assign(
          {},
          { id: -1, recipient_type: null, complete_mode: "one" }
        );
    }
  },

  methods: {
    OpenPermissionForm() {},

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (
          this.editedItem.recipient_type === "profile" ||
          this.editedItem.recipient_type === "user"
        ) {
          let l =
            this.editedItem.recipient_type === "profile"
              ? this.profiles
              : this.users;

          let i = l.findIndex((elm) => elm.id == this.editedItem.recipient_id);
          if (i >= 0) {
            this.editedItem.recipient_name =
              this.editedItem.recipient_type === "profile"
                ? l[i].description
                : l[i].name;
          }
        } else {
          this.editedItem.recipient_name =
            this.editedItem.recipient_type === "users"
              ? "Executions"
              : "Owners";
        }

        if (this.editedItem.id > 0) {
          let r = await this.maj(UPDATE_RECIPIENT, {
            eventapprecipient: {
              id: this.editedItem.id,
              recipient_id: this.editedItem.recipient_id,
              recipient_type: this.editedItem.recipient_type,
              send_by_email: this.editedItem.send_by_email,
              send_by_sms: this.editedItem.send_by_sms,
              complete_mode: this.editedItem.complete_mode,
              notify_only: this.editedItem.notify_only,
              write_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.splice(this.item.index, 1, this.editedItem);
          }
        } else {
          let r = await this.maj(CREATE_RECIPIENT, {
            eventapprecipient: {
              event_rule_id: this.rule.id,
              recipient_id: this.editedItem.recipient_id,
              recipient_type: this.editedItem.recipient_type,
              send_by_email: this.editedItem.send_by_email,
              send_by_sms: this.editedItem.send_by_sms,
              complete_mode: this.editedItem.complete_mode,
              notify_only: this.editedItem.notify_only,
              create_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.editedItem.id = r.createEventAppRecipient.id;
            this.items.push(this.editedItem);
          }
        }
        this.$emit("changed");
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
