<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      fluid
      v-model="showform2"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> {{ title }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <listitems
            :list="list"
            :title="'Components'"
            :headers="components_headers"
            :showstd="false"
            :showedit="false"
            :hdh_exp="false"
            :del="false"
            :add="false"
            :ipg="-1"
            :key="cmp_key"
            :hdf="true"
          >
          </listitems>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
  },
  props: {
    title: String,
    list: Array,
    showform: Boolean,
  },
  data() {
    return {
      showform2: true,
      cmp_key: 0,
      components_headers: [
        {
          text: "SN",
          value: "sn",
          selected: true,
          sortable: false,
        },
        {
          text: "Supplier",
          value: "supplier",
          selected: true,
          sortable: false,
        },

        {
          text: "Turns",
          value: "v_turns",
          selected: true,
          sortable: false,
        },
        {
          text: "Time",
          value: "v_time",
          selected: true,
          edit: true,
          sortable: false,
        },
        {
          text: "Size",
          value: "size",
          selected: true,
          edit: true,
          sortable: false,
        },
        {
          text: "Pressure Rating",
          value: "pressurerating",
          selected: true,
          slot: "cur33",
          align: "end",
          sortable: false,
        },

        {
          text: "Item Cost",
          value: "itemcost",
          selected: true,
          slot: "cur33",
          align: "end",
          sortable: false,
        },
        {
          text: "Date from",
          value: "date_in",
          selected: true,
          slot: "date",
          sortable: false,
        },
        {
          text: "Date to",
          value: "date_out",
          selected: true,
          slot: "date",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.cmp_key++;
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
