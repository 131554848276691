<template>
  <v-container fluid>
    <listitemspages
      :selitem="selitem"
      :qSelect="Qselect"
      :qSelectall="Qselectall"
      :PageResponse="'tests_pages'"
      :AllResponse="'tests_list'"
      :title="'Tests List'"
      :headers="headers"
      :field="'CODE'"
      :filename="'Test'"
      :sheetname="'Test'"
      :showedit="false"
      @open="TestOpen"
      @rowselect="TestChange"
      :Add="false"
      :del="false"
      :list_options="false"
      :key="klist"
      :search_elm="filtre"
      :CatScope="$store.state.fields"
    >
    </listitemspages>
    <div>
      <testform
        :showForm="!isTestClose"
        :well_list="[well]"
        :founds="testfounds"
        :well_failures="well_failures"
        :failures_list="failures_list"
        :barriers="barriers"
        :teststypes_list="teststypes_list"
        :apms="testapms"
        :xtrees="testxtrees"
        :downs="downs"
        :test_bo="testbo"
        :testdhsv="testdhsv"
        :item="test"
        :user_list="user_list"
        :status_list="status_list"
        :key="ktest"
        :editer="editer"
        :valider="valider"
        :testtype_id="parseInt(test.testtype_id)"
        :tab_label="tab_label"
        @close="isTestClose = true"
        @update="update"
      ></testform>
    </div>
  </v-container>
</template>

<script>
import DATA from "../graphql/WellTest/DATA.gql";

import TESTS_PAGES from "../graphql/WellTest/TESTS_PAGES.gql";
import TESTS_LIST from "../graphql/WellTest/TESTS_LIST.gql";

import TEST_APM from "../graphql/WellTest/TEST_APM.gql";
import TEST_HEADS from "../graphql/WellTest/TEST_HEADS.gql";

import TEST_DOWNS from "../graphql/WellTest/TEST_DOWNS.gql";
import TEST_BO from "../graphql/WellTest/TEST_BO.gql";
import TEST_DHSV from "../graphql/WellTest/TEST_DHSV.gql";
export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
    testform: () => import("../components/Test_Form.vue"),
  },
  data() {
    return {
      status_list: [],
      filtre: "",
      teststypes_list: [],
      wells_list: [],
      user_list: [],
      testfounds: [],
      well_failures: [],
      failures_list: [],
      barriers: [],
      testapms: [],
      testxtrees: [],
      downs: [],
      testbo: [],
      isTestClose: true,
      loading: false,
      klist: 100,
      ktest: 1000,
      selitem: {},
      testdhsv: {},
      tab_label: "Annuli pressure",
      headers: [
        {
          text: "Code",
          value: "code",
          enum: "CODE",
          slot: "href",
          selected: true,
        },
        {
          text: "Type",
          value: "testtype",
          enum: "TESTTYPE",
          list: [],
          selected: true,
        },
        {
          text: "Well",
          value: "wellname",
          enum: "WELLNAME",
          selected: true,
        },
        {
          text: "Statut",
          value: "status",
          enum: "STATUS",
          selected: true,
        },
        {
          text: "Date",
          value: "testdate",
          enum: "TESTDATE",
          selected: true,
        },
        {
          text: "Assigned To",
          value: "ownerName",
          selected: true,
        },
        {
          text: "Executed By",
          value: "userName",
          selected: true,
        },
        {
          text: "Interpreted at",
          value: "interpreted_at",
          enum: "INTERPRETEDDATE",
          selected: false,
        },
        {
          text: "Data entred by",
          value: "editedname",
          enum: "EDITEDNAME",
          selected: false,
        },
        {
          text: "Entred at",
          value: "created_at",
          enum: "EDITEDDATE",
          selected: false,
        },
      ],
      editer: false,
      valider: false,
      well: {},
      test: {},
    };
  },

  mounted() {
    this.editer =
      this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
    this.getData();
  },
  computed: {
    // QAPMdelete() {
    //   return DELETE_APM;
    // },
    Qselect() {
      return TESTS_PAGES;
    },
    Qselectall() {
      return TESTS_LIST;
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
  },
  watch: {
    "$store.state.fields": {
      handler() {
        this.klist++;
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },
  methods: {
    update(item) {
      this.test = item;
      this.selitem = item;
      // this.klist++;
    },
    TestChange(item) {
      this.test = item;
      this.selitem = item;
      this.well = { id: item.well_id, wellname: item.wellname };
    },
    async TestOpen(item) {
      if (item) {
        this.test = item;
        this.selitem = item;
        this.well = { id: item.well_id, wellname: item.wellname };
        this.tab_label = null;
        switch (parseInt(item.testtype_id)) {
          case 1:
            {
              this.tab_label = "Annuli pressure";
              await this.Get_APM(item);
            }
            break;
          case 4:
            {
              this.tab_label = "Test BO";
              await this.Get_BO(item);
            }
            break;
          case 7:
            {
              this.tab_label = "DHSV";

              await this.Get_DHSV(item);
            }
            break;

          case 3:
            {
              this.tab_label = "Xtree";
              await this.Get_HEADS(item);
            }
            break;
          case 5:
            {
              this.tab_label = "Wellhead/Hanger";
              await this.Get_HEADS(item);
            }
            break;
          case 6:
            {
              this.tab_label = "Downhole";
              await this.Get_DOWNS(item);
            }
            break;
        }

        this.ktest++;
        this.isTestClose = false;
      }
    },
    TestClose(item) {
      if (item) {
        this.klist++;
      } else {
        this.isTestClose = true;
      }
    },

    async getData() {
      this.loading = true;
      let r = await this.$requette(DATA);
      if (r.ok) {
        this.user_list = r.data.users;
        this.teststypes_list = r.data.teststypes_list;
        this.failures_list = r.data.failures_list;
        this.barriers = r.data.wmf_list;
        this.status_list = r.data.status_list;
        this.headers[1].list = this.teststypes_list.map(
          (elm) => elm.description
        );
      }
      this.loading = false;
    },

    async Get_APM(item) {
      let r = await this.$requette(
        TEST_APM,
        {
          TestID: parseInt(item.id),
          WellID: parseInt(item.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.testapms = r.data.test_well_apm;
        this.testfounds = r.data.test_well_found;
        this.well_failures = r.data.test_failures_list;
      }
    },
    async Get_HEADS(item) {
      let r = await this.$requette(
        TEST_HEADS,
        {
          TestID: parseInt(item.id),
          WellID: parseInt(item.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.testxtrees = r.data.test_well_valves;
        this.testfounds = r.data.test_well_found;
        this.well_failures = r.data.test_failures_list;
      }
    },
    async Get_DOWNS(item) {
      let r = await this.$requette(
        TEST_DOWNS,
        {
          TestID: parseInt(item.id),
          WellID: parseInt(item.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.downs = r.data.test_downhead;
        this.testfounds = r.data.test_well_found;
        this.well_failures = r.data.test_failures_list;
      }
    },
    async Get_BO(item) {
      let r = await this.$requette(
        TEST_BO,
        {
          TestID: parseInt(item.id),
          WellID: parseInt(item.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.testbo = r.data.test_well_bo;
        this.testfounds = r.data.test_well_found;
        this.well_failures = r.data.test_failures_list;
      }
    },
    async Get_DHSV(item) {
      let r = await this.$requette(
        TEST_DHSV,
        {
          TestID: parseInt(item.id),
          WellID: parseInt(item.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.testfounds = r.data.test_well_found;
        this.well_failures = r.data.test_failures_list;
        if (r.data.test_dhsv.length > 0) this.testdhsv = r.data.test_dhsv[0];
      }
    },
  },
};
</script>
