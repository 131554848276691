<template>
  <v-container fluid>
    <v-toolbar>
      <v-row align="center" dense>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <v-date-picker v-model="date" type="month" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="change_period(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <div v-if="plan.date_from" style="text-align: center">
          <h2>&nbsp;&nbsp; {{ title }}</h2>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          style="padding: 4px"
          v-if="current_operation.id > 0"
          @click.stop="action()"
          class="ma-1"
          color="blue darken-1"
        >
          {{ current_operation.label }}
        </v-btn>
        <v-btn
          v-if="
            plan.statut_id == 2 &&
            autorisations.findIndex((a) => a == '08003') >= 0 &&
            data_list.filter((x) => !x.owners || x.owners == null).length > 0
          "
          style="padding: 4px"
          @click.stop="attribution"
          class="ma-1"
          color="blue darken-1"
        >
          Assignement
        </v-btn>
        <!-- <v-btn v-if="plan.statut_id==2 && autorisations.findIndex((a) => a == '04003') >= 0" style="padding: 4px" @click.stop="attribution(2)" class="ma-1" color="blue darken-1">
                Execution
            </v-btn> -->
      </v-row>
    </v-toolbar>
    <v-row dense>
      <v-col cols="2">
        <listitems
          :title="'Planning List'"
          :list="plan_list"
          :header_shoose="false"
          :headers="plan_headers"
          :table_height="'500'"
          :showstd="false"
          :showedit="false"
          :master="false"
          :key="plan_key"
          :del="false"
          @open="create_plan()"
          @rowselect="select_planning"
          :add="can_create"
          :if_B="true"
          :btnB="'View'"
          :iconBName="'mdi-home'"
          @clickB="modal = true"
          :hdf="false"
        >
        </listitems>
      </v-col>
      <v-col cols="10">
        <listitems
          :title="'Tests List'"
          :list="data_list"
          :header_shoose="false"
          :headers="headers"
          :table_height="'500'"
          :showstd="true"
          :showedit="false"
          :master="true"
          :key="cor_key"
          :add="false"
          :del="false"
          @rowselect="select_test"
          @col_btn1_click="WaiverOpen"
          :hdf="false"
        >
        </listitems>
      </v-col>
    </v-row>
    <waiter :showWait="showWait"> </waiter>
    <waiverform
      :dialog="waiver_dialog"
      :waivers="waivers"
      :plan="plan"
      :test="test_sel"
      :key="wkey"
      :can_edit="
        autorisations.findIndex((a) => a == '03002') >= 0 &&
        current_operation.id == 1
      "
      :can_accept="
        plan.statut_id == 0 && autorisations.findIndex((a) => a == '04002') >= 0
      "
      :can_reject="
        plan.statut_id == 0 && autorisations.findIndex((a) => a == '06002') >= 0
      "
      @close_waiver="close_waiver"
    ></waiverform>
    <attrform
      :tests="data_list"
      :dialog="attr_dialog"
      :users="agents"
      :key="katt"
      @close_attr="close_attr"
      :plan="plan"
      :att_fp="plan.statut_id == 2"
      :att_ex="plan.statut_id == 2"
    ></attrform>
    <planinfo
      :key="kinfo"
      :dialog="infodialog"
      :infos="infos"
      :plan="plan"
      @CloseInfos="CloseInfos"
    >
    </planinfo>
    <TaskClose :ref="'task_close'" @completed="refresh"> </TaskClose>
    <confirmdialog ref="confirm" />
    <confirmdialog ref="accept" />
    <previsional
      :dialog="previsonal_dialog"
      :data_list="data_list_prev"
      :title="title_prev"
      @close_dialog="previsonal_dialog = false"
    />
  </v-container>
</template>

<script>
import Data from "../graphql/Planning/Data.gql";
import TestsPlanSelect from "../graphql/Planning/TestPlanSelect.gql";
import TestsWaivers from "../graphql/Planning/TestWaivers.gql";
import TestsCreate from "../graphql/Planning/TestsCreate.gql";
import PlanInfo from "../graphql/Planning/PlanningStatut.gql";
export default {
  components: {
    waiter: () => import("../components/Widgets/Waiter.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
    waiverform: () => import("../components/Waiver_List.vue"),
    attrform: () => import("../components/TestAttribut.vue"),
    planinfo: () => import("../components/PlanningInfo.vue"),
    TaskClose: () => import("../components/TaskCloseForm.vue"),
    previsional: () => import("../components/PrevisionalForm.vue"),
  },
  data() {
    return {
      previsonal_dialog: false,
      data_list_prev: [],
      title_prev: "",
      focalpoint: false,
      execution: false,
      can_create: false,
      infos: [],
      kinfo: 100000,
      infodialog: false,
      wkey: 0,
      showSelect: 0,
      plan_min_date: null,
      cs: 10000,
      cor_key: 20000,
      plan_key: 200000,
      katt: 300000,
      showWait: false,
      waiver_dialog: false,
      attr_dialog: false,
      test_sel: {},
      agents: [],

      headers: [
        {
          value: "operation",
          selected: true,
          slot: "chip",
          list: ["PT", "AT", "R"],
          width: "10",
          plan: "'1'",
          color: "color",
        },
        {
          text: "Well",
          value: "wellname",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Type",
          value: "welltype",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Last Date",
          value: "last_test",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Over Due Date",
          value: "overdue",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Assigned To",
          value: "ownerName",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Executed By",
          value: "userName",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Start Date",
          value: "start_date",
          selected: true,
          plan: "'2'",
        },
        {
          text: "Finish Date",
          value: "end_date",
          selected: true,
          plan: "'2'",
        },
        {
          text: "Next Due Date",
          value: "due_date",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Waiver Alternative Date",
          value: "waiver",
          selected: true,
          plan: "'2'",
        },
        {
          text: "Waiver Count",
          value: "waiver_count",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Waiver Task",
          value: "waiver_task",
          selected: true,
          plan: "'2'",
        },
        {
          value: "status",
          selected: true,
          text: "Status",
        },
        {
          text: "Edit Waiver",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
          plan: "'2'",
        },
      ],

      klist: 1,
      selbtn: 10000,
      tests_types: [],
      statuts: [],

      data_list: [], ///list de tests
      waivers: [], //list des waivers
      waiver: {},
      plan_list: [], //list des plan
      plan: {
        id: null,
        date_from: null,
        date_to: null,
        statut_id: null,
        periode: null,
      },
      plan_headers: [
        {
          value: "code",
          selected: true,
          text: "Code",
        },
        {
          value: "date_from",
          selected: true,
          text: "From",
        },
        {
          value: "date_to",
          selected: true,
          text: "TO",
        },
        {
          value: "status",
          selected: true,
          text: "Status",
        },
      ],
      autorisations: [],
      date: new Date().toISOString().substr(0, 7),
      start_date: null,
      modal: false,
      title: "",
    };
  },
  computed: {
    task_exist() {
      let t = null;
      if (this.plan)
        if (this.plan.tasks) {
          let l = this.plan.tasks.filter((elm) => elm.status_id < 2);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (element.users) {
              let i = element.users.findIndex(
                (elm) =>
                  elm.user_id == this.$store.state.me.id && elm.status_id < 2
              );
              if (i >= 0) {
                t = element.users[i];
                break;
              }
            }
          }
        }
      return t;
    },
    current_operation() {
      let label = {
        id: 0,
        value: "",
      };
      //Accept
      if (
        this.plan.statut_id == 0 &&
        this.task_exist &&
        this.data_list.length > 0 &&
        this.data_list.filter((x) => x.waiver_task > 0).length <= 0
      )
        label = {
          id: 1,
          label: "Accept",
        };
      //validate
      else if (this.plan.statut_id == 1 && this.task_exist)
        label = {
          id: 2,
          label: "Validate",
        };
      return label;
    },
  },
  async created() {
    this.autorisations = this.$store.state.me.autorisations.split(",");
    this.can_create = this.autorisations.findIndex((a) => a == "07002") >= 0;
    this.headers[10].selected =
      this.autorisations.findIndex((a) => a == "08002") >= 0;

    const today = new Date();
    let d00 = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));

    this.start_date = d00.toISOString().split("T")[0];

    await this.getdata();
    this.cs++;

    //01002 read
    //02002 accept or select
    //05002 validate
    //06002 att. fp---
    //07002 att. ex--
    //03002 Ask  Waiver
    //04002 Accept/Reject Waiver
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    getMonthName() {
      let monthNumber = this.date.split("-")[1];
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return (
        date.toLocaleString("en-US", {
          month: "long",
        }) +
        " " +
        this.date.split("-")[0]
      );
    },
    change_period(date) {
      this.plan = {};
      this.$refs.dialog.save(date);
      this.start_date = date + "-01";
      // Si la date selectionnee existe dans la liste des plannings;

      const filteredPlannings = this.plan_list.filter((planning) => {
        const dateFrom = new Date(planning.date_from);
        const dateTo = new Date(planning.date_to);
        const refDate = new Date(this.start_date);
        return refDate >= dateFrom && refDate <= dateTo;
      });

      if (filteredPlannings.length > 0) this.plan = filteredPlannings[0];

      //pour le label
      this.plan.date_from = this.plan_list[0].date_from; //le dernier planning
      this.plan.date_to = this.start_date; //la date selectionnée
      this.getplan();
    },
    async OpenInfos() {
      this.showWait = true;
      let r = await this.$requette(
        PlanInfo,
        {
          plan_id: this.plan.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.infos = r.data.state_plannings;
        this.infodialog = true;
      }

      this.showWait = false;

      this.kinfo++;
    },
    CloseInfos() {
      this.infodialog = false;
    },

    select_test(item) {
      this.test_sel = item;
    },

    action() {
      let id = this.current_operation.id;
      if (id == 1) this.accept_plan(1);
      else if (id == 2) this.accept_plan(2);
      if (id == 3) this.showattribution(1);
      if (id == 4) this.showattribution(2);
    },
    async select_planning(item) {
      this.plan = item;
      this.start_date = item.date_from;

      this.getplan();
    },
    async accept_plan() {
      //   if (
      await this.$refs.task_close.open(this.task_exist);
      //  )
      {
        this.$store.dispatch("Changed", true);
        this.getdata(parseInt(this.plan.id));
      }
    },
    async create_plan() {
      if (this.plan) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Create new planning  from " +
              this.plan.next_date_from +
              " to " +
              this.plan.next_date_to,
            {
              color: "orange darken-3",
            }
          )
        ) {
          this.save_plan(this.plan.next_date_from, this.plan.next_date_to);
        }

        this.cs++;
        this.showplan = true;
      }
    },
    async save_plan(d0, d1) {
      this.showWait = true;
      let r = await this.$requette(
        TestsCreate,
        {
          user_id: this.$store.state.me.id,
          date_from: d0,
          date_to: d1,
        },
        "no-cache"
      );
      if (r.ok) {
        this.$store.dispatch("Changed", true);
        this.date = d0;
        this.start_date = d0;
        this.getdata();
      }
      this.showWait = false;
    },
    WaiverSelect(item) {
      this.waiver = item;
      this.event_headers[5].disabled = item.edit == 0;
    },
    async WaiverOpen(item) {
      this.showWait = true;
      let r = await this.$requette(
        TestsWaivers,
        {
          test_id: item.id,
          plan_id: this.plan.id,
          user_id: this.$store.state.me.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.waivers = r.data.tests_events;

        this.waiver_dialog = true;
        this.wkey++;
      }
      this.showWait = false;
    },

    close_waiver(item) {
      if (item) {
        this.getplan();
      } else this.waiver_dialog = false;
    },
    close_attr(item) {
      if (item) {
        this.getplan();
      }
      this.attr_dialog = false;
      this.selbtn++;
    },
    attribution() {
      this.attr_dialog = true;
      this.katt++;
    },
    async getdata(id) {
      this.showWait = true;
      let r = await this.$requette(Data, {
        IdScope: id,
      });
      if (r.ok) {
        this.plan = {};
        this.agents = r.data.users;
        if (id) {
          let k = this.plan_list.findIndex((x) => x.id == id);
          if (k >= 0) this.plan_list[k] = r.data.planning_list[0];
        } else this.plan_list = r.data.planning_list;
        this.plan_key++;
        if (this.plan_list.length > 0) {
          this.plan = this.plan_list[0];
          this.start_date = this.plan.date_from;
          this.getplan();
        } else {
          if (this.can_create) {
            await this.getplan();
            this.create_plan();
          }
        }
      }

      this.showWait = false;
    },
    async getplan() {
      this.title = "";
      this.showWait = true;

      let r = await this.$requette(
        TestsPlanSelect,
        {
          start_date: this.start_date,
          user_id: this.$store.state.me.id,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.find_plan != null) {
          if (r.data.find_plan.details && r.data.find_plan.details != null)
            if (r.data.find_plan.planning.id == 0) {
              this.data_list = [];
              this.data_list = r.data.find_plan.details;
              this.title =
                " Planning " + this.plan.date_from + " - " + this.plan.date_to;
              this.cor_key++;
            } else if (this.plan.date_from) {
              this.data_list_prev = [];
              this.data_list_prev = r.data.find_plan.details;
              this.previsonal_dialog = true;
              this.title_prev =
                " Previsional " +
                this.plan.date_from +
                " - " +
                this.plan.date_to;
            }
          this.plan.next_date_from = r.data.find_plan.planning.next_date_from;
          this.plan.next_date_to = r.data.find_plan.planning.next_date_to;
        }
      }

      this.showWait = false;
    },

    search() {},
  },
};
</script>

<style scoped>
.center {
  text-align: center;
  background-color: #b8ababc0 !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  height: 30px;
  width: 200px;
}

.v-data-table >>> th {
  white-space: nowrap;
  border-bottom: none !important;
  /* border-right: 1px solid grey; */
}

.v-data-table >>> tr:hover {
  background: transparent !important;
}

.v-data-table >>> tr:nth-of-type(even) {
  background-color: transparent !important;
}

.v-data-table >>> tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.v-data-table >>> td {
  border-bottom: none !important;
  /* border-right: 1px solid grey; */
}

.v-tcard,
.v-card:hover {
  background-color: #b8ababc0 !important;
  cursor: pointer;
}
</style>
