<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="dialog"
        persistent
        width="auto"
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>{{ title }} </v-card-title>
          <v-card-text>
            <div>
              <listitems
                v-if="isTest != '1' || testtype == 10"
                :add="false"
                :del="false"
                :list="items"
                :headers="headers"
                :key="lkey"
                :Total="true"
                @col_btn1_click="well_click"
                @col_btn2_click="FingerOpen"
              ></listitems>
              <listitemspages
                v-else
                :ipg="10"
                :selitem="selitem"
                :qSelect="Qselect"
                :qSelectall="Qselectall"
                :PageResponse="'tests_pages'"
                :AllResponse="'tests_list'"
                :headers="headers"
                :field="'CODE'"
                :filename="'Test'"
                :sheetname="'Test'"
                :showedit="false"
                @open="TestOpen"
                @rowselect="TestChange"
                :Add="false"
                :del="false"
                :list_options="false"
                :key="klist"
                :CatScope="$store.state.fields"
                :FilterScope="'(1,2,3,4,5,6,8)'"
                :TypeScope="parseInt(testtype)"
                @col_btn2_click="TestOpen"
              >
              </listitemspages>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="$emit('close_dialog')"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <rapprt
      @click.stop
      :well_rapport="rapport_well"
      :dialog="rapport_dialog"
      :key="krapport"
      @close="rappor_close"
    />
    <testform
      :showForm="!isTestClose"
      :well_list="[well]"
      :founds="testfounds"
      :failures="failures"
      :all_failures="all_failures"
      :barriers="barriers"
      :teststypes_list="teststypes_list"
      :apms="testapms"
      :xtrees="testxtrees"
      :downs="downs"
      :test_bo="testbo"
      :testdhsv="testdhsv"
      :item="test"
      :user_list="user_list"
      :key="ktest"
      :editer="editer"
      :valider="valider"
      :testtype_id="parseInt(test.testtype_id)"
      @close="isTestClose = true"
      @update="update"
    ></testform>
    <fingerform
      :showform="!isFingerClose"
      :modify="well.statut_id == 1"
      :item="finger"
      :well="well"
      @close="isFingerClose = true"
      :fluids="fluids"
      :fluids_elements="fluids_elements"
      :wellsformations="formations_list.filter((elm) => elm.depth > 0)"
      :wellstubulars="
        tubulars_list.filter(
          (elm) => elm.depth > 0 && elm.depthfrom == 0 && elm.tubular_id != 1
        )
      "
      :user_list="user_list"
      :editer="editer"
      :valider="valider"
      :key="fingerkey"
    ></fingerform>
  </div>
</template>
<script>
import WELL_RAP from "../graphql/Well/WELL.gql";
import TESTFOUND from "../graphql/WellTest/APM/TestFound.gql";
import TESTS_PAGES from "../graphql/WellTest/TESTS_PAGES.gql";
import TESTS_LIST from "../graphql/WellTest/TESTS_LIST.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    listitemspages: () => import("../components/ListItemsPages.vue"),
    rapprt: () => import("../components/RapportWell.vue"),
    testform: () => import("../components/Test_Form.vue"),
    fingerform: () => import("../components/FingerForm.vue"),
  },
  props: {
    dialog: Boolean,
    headers: Array,
    qSelect: Object,
    search_elm: String,
    items: Array,
    title: String,
    testtype: Number,
    isTest: { type: String, default: "0" },

    fluids_elements: Array,
    fluids: Array,
  },
  data() {
    return {
      krapport: 100000,
      rapport_dialog: false,
      lkey: 0,
      rapport_well: {},
      //finger
      isFingerClose: true,
      finger: {},
      tubulars_list: [],
      formations_list: [],
      fingerkey: 2050,
      //------
      teststypes_list: [],
      wells_list: [],
      user_list: [],
      testfounds: [],
      failures: [],
      all_failures: [],
      barriers: [],
      testapms: [],
      testxtrees: [],
      downs: [],
      testbo: [],
      isTestClose: true,
      loading: false,
      klist: 100,
      ktest: 1000,
      selitem: {},
      testdhsv: {},
      editer: false,
      valider: false,
      well: {},
      test: {},
    };
  },
  computed: {
    Qselect() {
      return TESTS_PAGES;
    },
    Qselectall() {
      return TESTS_LIST;
    },
  },
  mounted() {
    if (this.isTest == "1") {
      this.editer =
        this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
      this.valider =
        this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
      // if (this.testtype == 10) this.getTests();
    }
  },
  methods: {
    rappor_close() {
      this.rapport_dialog = false;
    },
    async well_click(item) {
      let r = await this.$requette(
        WELL_RAP,
        {
          TypeScope: parseInt(item.well_id),
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.allwellsstates.length > 0) {
          this.rapport_well = r.data.allwellsstates[0];
          this.rapport_dialog = true;
          this.krapport++;
        }
      }
    },
    update(item) {
      this.test = item;
      this.selitem = item;
      this.klist++;
    },

    TestClose(item) {
      if (item) {
        this.klist++;
      } else {
        this.isTestClose = true;
      }
    },
    TestChange(item) {
      this.test = item;
      this.selitem = item;
      this.well = { id: item.well_id, wellname: item.wellname };
    },
    ////////////////////////////
    async TestOpen(item) {
      this.test = item;
      this.selitem = item;
      this.well = { id: item.well_id, wellname: item.wellname };
      this.tab_label = null;

      if (item) {
        let r = await this.$requette(
          TESTFOUND,
          {
            TestID: parseInt(item.id),
            WellID: parseInt(item.well_id),
          },
          "no-cache"
        );
        if (r.ok) {
          this.testfounds = r.data.test_well_found;
          this.testxtrees = r.data.test_well_valves;
          this.testapms = r.data.test_well_apm;
          this.downs = r.data.test_downhead;
          this.failures = r.data.test_failures_list;
          this.all_failures = r.data.failures_list;
          this.barriers = r.data.wmf_list;

          this.testbo = r.data.test_well_bo;
          if (r.data.test_dhsv.length > 0) this.testdhsv = r.data.test_dhsv[0];
        }
        this.isTestClose = false;
        this.ktest++;
      }
    },
    async FingerOpen(item) {
      let r = await this.$requette(
        WELL_RAP,
        {
          TypeScope: parseInt(item.well_id),
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.allwellsstates.length > 0) {
          this.well = r.data.allwellsstates[0];

          this.tubulars_list = r.data.allwellsstates[0].wellstubulars;
          this.formations_list = r.data.allwellsstates[0].wellsformations;
          this.finger = item;
          this.fingerkey++;
          this.isFingerClose = false;
        }
      }
    },
    async CementOpen() {},
    async CorrosionOpen() {},
  },
};
</script>
