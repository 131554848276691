<template>
  <div>
    <v-dialog v-model="showForm" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          <v-icon :color="color">{{ icon }}</v-icon>

          <span class="mx-2">
            {{ title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            small
            @click.stop="markAllAsRead"
            v-if="unreadCount > 0"
          >
            <v-icon color="green">mdi-check</v-icon>
            Mark all as read
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <!-- Display individual notifications -->
            <v-list-item
              v-for="notification in notifications.filter(
                (elm) => elm.is_read == 0
              )"
              :key="notification.id"
              :class="{ 'notification-read': notification.is_read }"
              @click="openNotification(notification)"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  notification.message
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <!-- Display the mark as read button only if the notification is unread -->
              <v-list-item-action v-if="!notification.is_read">
                <v-btn icon @click.stop="markAsRead(notification.id)">
                  <v-icon color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" @click="viewAllNotifications"
            >View All</v-btn
          >
          <v-btn small color="blue darken-1" @click="close"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MARK_AS_READ from "@/graphql/Notifications/MARK_AS_READ.gql";
import MARK_ALL_AS_READ from "@/graphql/Notifications/MARK_ALL_AS_READ.gql";

export default {
  name: "DefaultNotifications",
  props: {
    showForm: Boolean,
    notifications: Array,
    title: String,
    icon: String,
    color: String,
  },
  data() {
    return {
      unreadCount: 0,
    };
  },
  watch: {},
  mounted() {
    this.unreadCount = this.notifications.filter((n) => !n.is_read).length;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    viewAllNotifications() {
      this.$emit("close");
      this.$router.push({ name: "notifHistory" });
    },
    // Open notification and handle any required logic
    openNotification(item) {
      if (this.$router.currentRoute.name != item.interface)
        this.$router.push({
          name: item.interface,
          params: { filtre: item.filter_by, obj: { id: item.reference_id } },
        });
      else {
        if (item.filter_by)
          this.$store.dispatch("Filtre", {
            filter_by: item.filter_by,
            obj: { id: item.reference_id },
          });
      }
      this.close();
      // if (!notification.is_read) {
      //   this.markAsRead(notification.id); // Mark as read when opened
      // }
      // Handle other actions, e.g., opening a notification detail view
    },

    // Mark individual notification as read
    async markAsRead(notificationId) {
      let r = await this.$apollo.mutate({
        mutation: MARK_AS_READ,
        variables: { id: notificationId },
      });
      if (r) {
        // Update UI
        this.notifications = this.notifications.map((n) =>
          n.id === notificationId ? { ...n, is_read: true } : n
        );
        this.unreadCount = this.notifications.filter((n) => !n.is_read).length;
        this.$emit("refresh");
      }
    },

    // Mark all notifications as read
    async markAllAsRead() {
      if (this.unreadCount > 0) {
        let r = await this.$apollo.mutate({
          mutation: MARK_ALL_AS_READ,
          variables: { user_id: this.$store.state.me.id },
        });
        if (r) {
          // Update UI after marking all as read
          this.notifications = this.notifications.map((n) => ({
            ...n,
            is_read: true,
          }));
          this.unreadCount = 0;
          this.$emit("refresh");
        }
      }
    },
  },
};
</script>

<style scoped>
.notification-read {
  opacity: 0.6;
}
.mark-all {
  background-color: #f0f0f0;
  cursor: pointer;
}
</style>
