<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="900px" persistent>
        <v-card>
          <v-card-title>
            <span class="mx-2"> {{ formTitle }} </span>
            <v-spacer></v-spacer>
            <span class="mr-4" v-if="editedItem.id > 0"
              ><strong>Due Date:</strong>
              {{ formatDate(editedItem.due_date) }}</span
            >
            <v-spacer></v-spacer>
            <v-chip :color="editedItem.color" small dark>{{
              editedItem.status_name
            }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.title"
                  label="Title"
                  :rules="[(v) => !!v || 'Title required !!']"
                  :readonly="editedItem.id > 0"
                  @change="changes++"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="
                  editedItem.create_uid == $store.state.me.id ||
                  editedItem.id < 0
                "
              >
                <datepicker
                  label="Due Date"
                  v-model="editedItem.due_date"
                  :edit="editedItem.status_id < 2"
                  :rules="[(v) => !!v || 'Due Date required']"
                  :date_min="$store.state.today"
                  :key="kdd"
                  :clearable="false"
                  @change="changes++"
                ></datepicker>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.id < 0">
                <v-checkbox
                  label="Email"
                  v-model="editedItem.send_by_email"
                  dense
                  @change="changes++"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  editedItem.create_uid == $store.state.me.id ||
                  editedItem.id < 0
                "
              >
                <v-select
                  dense
                  :items="
                    $store.state.users.filter(
                      (elm) =>
                        elm.autorisations.includes('01113') ||
                        elm.profile.autorisations.includes('01113')
                    )
                  "
                  v-model="selected_users"
                  item-text="name"
                  item-value="id"
                  label="Users"
                  chips
                  :rules="[(v) => selected_users.length > 0 || 'User required']"
                  multiple
                  :readonly="editedItem.id > 0"
                  @change="changes++"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-tabs
                  v-model="activeTab"
                  background-color="grey lighten-4"
                  light
                >
                  <v-tab href="#1" key="1"> Infos </v-tab>
                  <v-tab
                    href="#2"
                    key="2"
                    v-if="editedItem.create_uid == $store.state.me.id"
                  >
                    Users
                  </v-tab>
                  <v-tab href="#3" key="3" v-if="editedItem.id > 0">
                    Files
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="activeTab"
                  style="height: 300px; overflow-y: auto"
                >
                  <v-tab-item :value="'1'">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            autocomplete="off"
                            type="text"
                            outlined
                            dense
                            v-model="editedItem.message"
                            label="Message"
                            :rules="[(v) => !!v || 'Message required !!']"
                            :readonly="editedItem.id > 0"
                            @change="changes++"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <template>
                      <v-container fluid>
                        <v-list two-line width="1000">
                          <v-list-item-group>
                            <v-list-item
                              v-for="task in editedItem.tasks"
                              :key="task.id"
                              class="mb-4"
                            >
                              <!-- Task Details Card -->
                              <v-card class="mb-2" outlined>
                                <v-card-title>
                                  <div>
                                    <div class="headline">
                                      {{ task.title || "Untitled Task" }}
                                    </div>
                                  </div>
                                </v-card-title>
                                <v-card-subtitle>
                                  Created At: {{ task.created_at }} - Completed
                                  At: {{ task.completed_at || "Ongoing" }}
                                </v-card-subtitle>
                                <v-card-text>
                                  <v-chip
                                    small
                                    :color="task.color || 'grey'"
                                    dark
                                    >{{ task.status_name }}</v-chip
                                  >
                                  <div>Users Count: {{ task.nbusers }}</div>
                                </v-card-text>

                                <!-- Expanded User Table -->
                                <v-data-table
                                  :headers="userHeaders"
                                  :items="task.users"
                                  item-key="id"
                                  dense
                                  hide-default-footer
                                  class="mt-4"
                                >
                                  <!-- Custom Columns for User Table -->
                                  <template
                                    v-slot:[`item.user_name`]="{ item }"
                                  >
                                    <span>{{ item.user_name }}</span>
                                  </template>
                                  <template
                                    v-slot:[`item.status_name`]="{ item }"
                                  >
                                    <v-chip
                                      small
                                      :color="item.color || 'grey'"
                                      dark
                                      >{{ item.status_name }}</v-chip
                                    >
                                  </template>
                                  <template v-slot:[`item.comment`]="{ item }">
                                    <span>{{
                                      item.comment || "No Comment"
                                    }}</span>
                                  </template>
                                  <template v-slot:[`item.nbdocs`]="{ item }">
                                    <span>{{ item.nbdocs || 0 }}</span>
                                  </template>
                                </v-data-table>
                              </v-card>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-container>
                    </template>
                  </v-tab-item>
                  <v-tab-item :value="'3'">
                    <filelist
                      :item="editedItem"
                      :editer="true"
                      :isform="false"
                      :doc_type="21"
                      :user_id="
                        editedItem.create_uid == $store.state.me.id
                          ? null
                          : parseInt($store.state.me.id)
                      "
                    ></filelist>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              color="blue darken-1"
              @click="complete"
              v-if="canComplete"
              >{{
                editedItem.statut_id == 0 ||
                editedItem.statut_id == 1 ||
                editedItem.statut_id == 3
                  ? "Complete"
                  : "Evaluate"
              }}</v-btn
            >

            <v-btn
              small
              color="gray darken-1"
              @click="cancel_request"
              v-if="
                editedItem.create_uid == $store.state.me.id &&
                editedItem.status_id < 2
              "
              >Cancel Request</v-btn
            >
            <v-spacer></v-spacer>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>

            <v-spacer></v-spacer>
            <v-btn
              small
              color="blue darken-1"
              @click="save(0)"
              :disabled="changes == 0"
              >Save</v-btn
            >

            <v-btn small color="blue darken-1" @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    </template>
    <TaskClose :ref="'task_close'" @completed="task_close"> </TaskClose>
  </div>
</template>

<script>
import CREATE_REQUEST from "@/graphql/Tasks/CREATE_REQUEST.gql";
import UPDATE_REQUEST from "@/graphql/Tasks/UPDATE_REQUEST.gql";
export default {
  name: "RequestForm",
  components: {
    confirmdialog: () => import("../ConfirmDialog.vue"),
    datepicker: () => import("../DatePicker.vue"),
    filelist: () => import("../FileList.vue"),
    TaskClose: () => import("../TaskCloseForm.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    task: Object,
  },
  data() {
    return {
      title: null,
      activeTab: 0,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      kdd: 100,
      valid: true,
      progress: false,
      changes: 0,
      editedItem: {},
      is_motif: false,
      selected_users: [],
      users: [],
      taskHeaders: [
        { text: "Task", value: "title" },
        { text: "Status", value: "status_name" },
        { text: "Users Count", value: "nbusers" },
        { text: "Created At", value: "created_at" },
        { text: "Completed At", value: "completed_at" },
        { text: "", value: "data-table-expand" },
      ],
      // Headers for users table
      userHeaders: [
        { text: "User Name", value: "user_name", width: "200" },
        { text: "Task Status", value: "status_name" },
        { text: "Comment", value: "comment", width: "200" },
        { text: "Files", value: "nbdocs" },
      ],
      defaultItem: {
        id: -1,
        title: null,
        message: null,
        due_date: null,
        status_id: 0,
        color: "purple",
        send_by_email: 0,
        status_name: "Pending",
      },
    };
  },
  watch: {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        if (this.editedItem.users)
          this.selected_users = this.editedItem.users.split(",");
        this.kdd++;
      } else this.editedItem = Object.assign({}, this.defaultItem);
    }
    this.$nextTick(() => {
      this.changes = 0;
    });
  },
  computed: {
    canComplete() {
      let ok = false;

      if (this.editedItem)
        if (this.editedItem.tasks) {
          let i = this.editedItem.tasks.findIndex((elm) => elm.status_id < 2);

          if (i >= 0) {
            if (this.editedItem.tasks[i].users) {
              let j = this.editedItem.tasks[i].users.findIndex(
                (elm) => elm.user_id == this.$store.state.me.id
              );
              if (j >= 0) {
                ok = this.editedItem.tasks[i].users[j].status_id < 2;
              }
            }
          }
        }

      return ok;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "New Request "
          : "Request N° " + this.editedItem.code;
      else return "";
    },
  },
  methods: {
    async complete() {
      await this.$refs.task_close.open(this.task);
    },

    task_close() {
      this.refresh();
      this.close();
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    close() {
      this.$emit("close");
    },
    async cancel_request() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Are you sure you want to Cancel this request?",
          { color: "orange darken-3" }
        )
      ) {
        this.is_motif = false;
        this.save(5);
      }
    },
    refresh() {
      this.$emit("refresh");
    },
    // Mark individual notification as read
    async save(status) {
      if (this.$refs.form.validate()) {
        this.progress = true;
        if (this.editedItem.id < 0) {
          let r = await this.$apollo.mutate({
            mutation: CREATE_REQUEST,
            variables: {
              request: {
                title: this.editedItem.title,
                message: this.editedItem.message,
                due_date: this.editedItem.due_date,
                statut_id: 0,
                users: this.selected_users.toString(),
                owners: this.$store.state.me.id,
                create_uid: this.$store.state.me.id,
              },
            },
          });
          this.progress = false;
          if (r) {
            this.refresh();
            this.close();
            this.changes++;
            this.snackbar = true;
            this.snackbar_text = "Request added successfully";
            this.snackbar_color = "success";
          }
        } else {
          let r = await this.$apollo.mutate({
            mutation: UPDATE_REQUEST,
            variables: {
              request: {
                id: this.editedItem.id,
                title: this.editedItem.title,
                message: this.editedItem.message,
                due_date: this.editedItem.due_date,
                status_id: status,
                motif: this.editedItem.motif,
                users: this.selected_users.toString(),
              },
            },
          });
          this.progress = false;
          if (r) {
            this.refresh();
            if (status == 5) this.close();
          }
        }
        this.changes = 0;
      }
    },
  },
};
</script>
