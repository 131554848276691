<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    persistent
    transition="dialog-top-transition"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        {{ title }}</v-card-title
      >
      <v-card-text>
                  <listitems
      :title="'Tests List'"
      :list="data_list"
      :header_shoose="false"
      :headers="headers"
      :showstd="true"
      :showedit="false"
      :master="true"
      :key="cor_key"
      :add="false"
      :del="false" 
    >
    </listitems>
        </v-card-text>
                <v-spacer></v-spacer>
        <v-btn class="ma-1" color="blue darken-1" @click="close_dialog()">
          Close
        </v-btn>
         </v-card>
 </v-dialog>
 </template> 
 <script>
    export default {
  components: { 
    listitems: () => import("./ListItems.vue"), 
  },
  props: {
    dialog: Boolean,
    data_list: Array,  
    title:String,
  },
  data() {
    return { 
      add: false,
      waiver_dialog: false,
      cor_key: 200, 
    headers: [
        {
          value: "operation",
          selected: true,
          slot: "chip",
          list: ["PT", "AT", "R"],
          width: "10",
          plan: "'1'",
          color: "color",
        },
        {
          text: "Well",
          value: "wellname",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Type",
          value: "welltype",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Last Date",
          value: "last_test",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Over Due Date",
          value: "overdue",
          selected: true,
          plan: "'1'",
        },
        {
          text: "Iteration",
          value: "frequency",
          selected: true,
          plan: "'3'",
        }, 
        {
          text: "Next Due Date",
          value: "due_date",
          selected: true,
          plan: "'1'",
        }, 
        {
          text: "Waiver Count",
          value: "waiver_count",
          selected: true,
          plan: "'1'",
        }, 
      ],
    };
  },
  mounted() { 
  
  },
  methods:{
        close_dialog() {
      this.$emit("close_dialog"); 
    },
  }
}
    </script>