<template>
  <div>
    <v-menu
      v-model="menu"
      offset-y
      max-width="900px"
      max-height="900px"
      :close-on-content-click="false"
      content-class="custom-menu"
    >
      <!-- Activator -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-badge
            :content="tasks.length"
            :value="tasks.length"
            overlap
            color="red"
          >
            <v-icon>mdi-alpha-t-circle</v-icon>
          </v-badge>
          <v-badge
            :content="requests.length"
            :value="requests.length"
            overlap
            color="blue"
          >
            <v-icon>mdi-account-question</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <!-- Contenu du menu -->
      <v-card max-width="900px" max-height="900px" class="custom-card">
        <v-card-title>
          Pending Items
          <v-spacer></v-spacer>
          <v-btn
            v-if="canAddRequest && activeTab === 1"
            color="primary"
            text
            @click="addRequest"
          >
            Add Request
          </v-btn>
          <v-btn color="primary" text @click="viewAllTasks">View all</v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <!-- Tabs -->
        <v-tabs
          v-model="activeTab"
          background-color="grey lighten-4"
          light
          @change="tabChange"
          @click.stop
        >
          <v-tab>
            Tasks
            <v-badge
              :content="tasks.length"
              color="red"
              class="badge-align"
              overlap
            ></v-badge>
          </v-tab>
          <v-tab>
            Requests
            <v-badge
              :content="requests.length"
              color="blue"
              class="badge-align"
              overlap
            ></v-badge>
          </v-tab>
        </v-tabs>

        <!-- Tabs content -->
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <div class="scrollable-content" @click.stop>
              <listitems
                :list="tasks"
                :title="'Tasks'"
                :headers="task_header"
                :key="kt"
                :master="true"
                :showedit="false"
                :add="false"
                :del="false"
                :Total="false"
                @open="TaskClick"
                :exp_excel="false"
                :laoding="loading"
                :table_height="'600px'"
              ></listitems>
            </div>
          </v-tab-item>
          <v-tab-item v-if="canAddRequest">
            <div class="scrollable-content" @click.stop>
              <listitems
                :list="requests"
                :title="'Requests'"
                :headers="task_header"
                :key="kr"
                :master="true"
                :showedit="false"
                :add="false"
                :del="false"
                :Total="false"
                @open="RequestClick"
                :exp_excel="false"
                :table_height="'600px'"
              ></listitems>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-menu>
    <component
      :is="isRequestFormOpen ? 'requestform' : null"
      :showForm="isRequestFormOpen"
      :item="request"
      :task="task"
      :key="kf"
      @close="isRequestFormOpen = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import GET_TASKS from "@/graphql/Tasks/GET_TASKS.gql";
import GET_REQUESTS from "@/graphql/Tasks/GET_REQUESTS.gql";
import REQUEST from "@/graphql/Tasks/REQUEST.gql";
export default {
  name: "DefaultTasks",
  components: {
    requestform: () => import("./RequestForm.vue"),
    listitems: () => import("../ListItems.vue"),
  },
  data() {
    return {
      menu: false,
      showTasks: false,
      loading: false,
      tasks: [],
      requests: [],
      request: {},
      task_header: [
        {
          text: "N°",
          value: "no",
          selected: true,
          totlib: true,
        },
        {
          text: "Code",
          value: "code",
          slot: "href",
          selected: true,
        },
        {
          text: "Title",
          value: "title",
          selected: true,
        },
        {
          text: "Message",
          value: "message",
          selected: true,
        },
        {
          text: "Requested By",
          value: "created_by_name",
          selected: true,
          slot: "text_icon",
          icon: "mdi-dots-horizontal",
          column: "nbusers",
          valeur: "1",

          hoover_list: "userslist",
          hoover_name: "user_name",
          hoover_value: "status_name",
          sortable: false,
          index: 0,
        },
        {
          text: "Due Date",
          value: "due_date",
          slot: "date",
          selected: true,
        },
        {
          text: "Created At",
          value: "created_at",
          slot: "date",
          selected: false,
        },
        {
          text: "Status",
          value: "status_name",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      kt: 100,
      kr: 200,
      task: {},
      unreadCount: 0,
      isRequestFormOpen: false,
      kf: 100,
      activeTab: 0, // 0 for Tasks, 1 for Requests
    };
  },
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          this.refresh();
        }
      },
    },
  },
  computed: {
    canAddRequest() {
      return (
        this.$store.state.auth.includes("01112") || this.$store.state.isadmin
      );
    },
  },
  mounted() {
    this.refresh();
    setInterval(() => {
      this.refresh();
    }, 60000);
    this.tabChange();
  },
  methods: {
    tabChange() {
      let x = this.menu;
      this.task_header[4].text =
        this.activeTab === 0 ? "Requested By" : "Users";
      this.task_header[4].value =
        this.activeTab === 0 ? "created_by_name" : "user_name";
      this.task_header[4].slot = this.activeTab === 0 ? null : "text_icon";
      if (x) this.menu = true;
    },

    addRequest() {
      this.request.id = -1;
      this.isRequestFormOpen = true;
      this.kf += 1;
      this.menu = false;
    },
    viewAllTasks() {
      // event.stopPropagation();
      this.$router.push({ name: "taskHistory" });
      this.showTasks = false;
      this.menu = false;
    },
    async refresh() {
      const tasksResult = await this.$apollo.query({
        query: GET_TASKS,
        variables: { user_id: this.$store.state.me.id, all: 2 },
        fetchPolicy: "network-only",
      });

      const requestsResult = await this.$apollo.query({
        query: GET_REQUESTS,
        variables: { create_uid: this.$store.state.me.id, all: 2 },
        fetchPolicy: "network-only",
      });

      if (tasksResult) {
        this.tasks = tasksResult.data.userTasks.filter(
          (task) => task.status_id < 2
        );
        this.kt++;
      }
      if (requestsResult) {
        this.requests = requestsResult.data.userPendingRequests.filter(
          (request) => request.statut_id < 4
        );
        this.kr++;
      }
      this.$store.dispatch("Changed", false);
    },
    async TaskClick(item) {
      if (item) {
        if (item.interface == "requests") {
          this.loading = true;
          const request = await this.$apollo.query({
            query: REQUEST,
            variables: { rsq_id: item.reference_id },
            fetchPolicy: "network-only",
          });
          this.loading = false;
          if (request)
            if (request.data)
              if (request.data.request) {
                this.request = request.data.request;
                this.task = item;
                this.isRequestFormOpen = true;
                this.kf += 1;
              }
        } else {
          if (this.$router.currentRoute.name != item.interface)
            this.$router.push({
              name: item.interface,
              params: {
                filtre: item.filter_by,
                obj: { id: item.reference_id },
              },
            });
          else if (item.filter_by)
            this.$store.dispatch("Filtre", {
              filter_by: item.filter_by,
              obj: { id: item.reference_id },
            });

          if (item.interface) {
            this.showTasks = false;
            this.menu = false;
          }
        }
      }
    },
    RequestClick(item) {
      this.request = item;
      this.isRequestFormOpen = true;
      this.kf += 1;
    },
  },
};
</script>

<style>
.custom-menu {
  overflow: hidden;
}

.custom-card {
  max-height: 900px;

  overflow-y: hidden; /* Pas de débordement ici */
  background-color: white;
}

.scrollable-content {
  max-height: 400px;
  max-height: 900px;
  overflow-y: auto;
}

.badge-align {
  margin-left: 4px;
  margin-top: -4px;
}
</style>
