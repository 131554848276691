<template>
  <div
    ref="threeContainer"
    style="width: 100vw; height: 100vh; position: relative"
  >
    <!-- Conteneur pour les indications de navigation -->
    <div
      style="
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
      "
    >
      <h3>Navigation</h3>
      <ul>
        <li><b>Left Clic :</b> Rotation</li>
        <li><b>Right Clic droit :</b> shift</li>
        <li><b>Wheel :</b> Zoom</li>
      </ul>
    </div>
    <div></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  name: "Geo3DScene",
  data() {
    return {
      fieldData: {
        formations: [
          {
            id: 1,
            name: "Formation A",
            depthFrom: 0,
            depthTo: 200,
            texture: "desert.jpg",
          },
          {
            id: 2,
            name: "Formation B",
            depthFrom: 200,
            depthTo: 500,
            texture: "rock.jpg",
          },
          {
            id: 3,
            name: "Formation C",
            depthFrom: 500,
            depthTo: 800,
            texture: "sand.jpg",
          },
          {
            id: 4,
            name: "Formation D",
            depthFrom: 800,
            depthTo: 3500,
            texture: "clay.jpg",
          },
        ],
        wells: [
          {
            id: 1,
            name: "Well A",
            position: { lat: 34.0522, lon: -118.2437 },
            depth: 800,
            formations: [
              { formationId: 1, depthFrom: 0, depthTo: 200 },
              { formationId: 2, depthFrom: 200, depthTo: 500 },
              { formationId: 3, depthFrom: 500, depthTo: 800 },
            ],
          },
          {
            id: 2,
            name: "Well B",
            position: { lat: 34.0525, lon: -118.244 },
            depth: 1200,
            formations: [
              { formationId: 1, depthFrom: 0, depthTo: 100 },
              { formationId: 3, depthFrom: 100, depthTo: 300 },
            ],
          },
          {
            id: 3,
            name: "Well C",
            position: { lat: 34.053, lon: -118.245 },
            depth: 1000,
            formations: [
              { formationId: 2, depthFrom: 200, depthTo: 500 },
              { formationId: 3, depthFrom: 500, depthTo: 800 },
            ],
          },
          {
            id: 4,
            name: "Well D",
            position: { lat: 34.0535, lon: -118.247 },
            depth: 5000,
            formations: [
              { formationId: 2, depthFrom: 200, depthTo: 500 },
              { formationId: 3, depthFrom: 500, depthTo: 800 },
              { formationId: 4, depthFrom: 800, depthTo: 3500 },
            ],
          },
        ],
        scene: null, // Stocke la scène
        selectedWells: [], // Wells sélectionnés
        selectedFormations: [], // Formations sélectionnées
      },
    };
  },
  mounted() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xeeeeee);
    this.init3DScene();
  },
  methods: {
    resetScene() {
      // Parcourir tous les enfants de la scène et les supprimer
      while (this.scene.children.length > 0) {
        const child = this.scene.children[0];
        if (child.geometry) child.geometry.dispose(); // Nettoyer la géométrie
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach((mat) => mat.dispose());
          } else {
            child.material.dispose(); // Nettoyer le matériau
          }
        }
        this.scene.remove(child); // Supprimer de la scène
      }
    },
    // Méthode pour mettre à jour la scène en fonction de la sélection
    updateScene(selectedWells, selectedFormations) {
      // Réinitialiser la scène
      this.resetScene();

      // Reconstruire les éléments de la scène en fonction des nouvelles sélections
      this.createFormations(this.scene, selectedFormations, selectedWells);
      this.createWells(this.scene, selectedWells);
    },
    init3DScene() {
      // const scene = new THREE.Scene();
      // scene.background = new THREE.Color(0xeeeeee);

      // Caméra
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        20000
      );
      camera.position.set(0, 1000, 3000);

      // Rendu
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.threeContainer.appendChild(renderer.domElement);

      // Contrôles de navigation
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.minDistance = 500;
      controls.maxDistance = 10000;
      const gridHelper = new THREE.GridHelper(10000, 50, 0x444444, 0x888888);
      this.scene.add(gridHelper);
      // Ajout des formations et puits
      this.createFormations(
        this.scene,
        this.fieldData.formations,
        this.fieldData.wells
      );
      this.createWells(this.scene, this.fieldData.wells);

      // Animation
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(this.scene, camera);
      };
      animate();

      // Gestion du redimensionnement
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    },
    createFormations(scene, formations, wells) {
      const textureLoader = new THREE.TextureLoader();
      wells.forEach((well) => {
        if (Array.isArray(well.formations) && well.formations.length > 0) {
          well.formations.forEach((formation) => {
            const currentFormation = formations.find(
              (f) => f.id === formation.formationId
            );
            if (currentFormation) {
              const height = 2 * (formation.depthTo - formation.depthFrom);
              const geometry = new THREE.PlaneGeometry(10000, 10000, 200, 200);
              const texture = textureLoader.load(
                require(`@/assets/${currentFormation.texture}`)
              );

              const material = new THREE.MeshBasicMaterial({
                map: texture,
                side: THREE.DoubleSide,
              });

              // Déformation pour une surface réaliste
              const amplitude = 20;
              const randomFactor = 5;
              geometry.attributes.position.array.forEach((_, index) => {
                if (index % 3 === 2) {
                  const x = geometry.attributes.position.array[index - 2];
                  const y = geometry.attributes.position.array[index - 1];
                  geometry.attributes.position.array[index] =
                    Math.sin(x * 0.005) * amplitude +
                    Math.cos(y * 0.005) * amplitude +
                    (Math.random() - 0.5) * randomFactor;
                }
              });
              geometry.computeVertexNormals();

              // Position et ajout de chaque formation
              const layer = new THREE.Mesh(geometry, material);
              layer.position.set(0, -formation.depthFrom - height / 2, 0);
              layer.rotation.x = Math.PI / 2;
              scene.add(layer);

              // Nom de la formation
              const canvas = document.createElement("canvas");
              canvas.width = 512;
              canvas.height = 128;
              const context = canvas.getContext("2d");
              context.font = "32px Arial";
              context.fillStyle = "black";
              context.textAlign = "center";
              context.fillText(
                currentFormation.name,
                canvas.width / 2,
                canvas.height / 2
              );

              const texture2 = new THREE.CanvasTexture(canvas);
              const spriteMaterial = new THREE.SpriteMaterial({
                map: texture2,
              });
              const sprite = new THREE.Sprite(spriteMaterial);
              sprite.scale.set(1000, 250, 1);

              sprite.position.set(0, -formation.depthFrom - height / 2 + 20, 0);
              scene.add(sprite);
            }
          });
        }
      });
    },

    convertLatLongToXZ(lat, lon, referenceLat, referenceLon) {
      const earthRadius = 6371000; // Rayon de la Terre en mètres
      const scaleFactor = 3; // Ajustez ce facteur pour adapter les distances

      const latRad = THREE.MathUtils.degToRad(lat);
      const lonRad = THREE.MathUtils.degToRad(lon);
      const refLatRad = THREE.MathUtils.degToRad(referenceLat);
      const refLonRad = THREE.MathUtils.degToRad(referenceLon);

      const deltaLat = latRad - refLatRad;
      const deltaLon = lonRad - refLonRad;

      const x = deltaLon * earthRadius * Math.cos(refLatRad) * scaleFactor;
      const z = deltaLat * earthRadius * scaleFactor;
      return { x, z };
    },
    createWells(scene, wells) {
      const referenceWell = wells[0];
      const referenceLat = referenceWell.position.lat;
      const referenceLon = referenceWell.position.lon;

      wells.forEach((well) => {
        const { x, z } = this.convertLatLongToXZ(
          well.position.lat,
          well.position.lon,
          referenceLat,
          referenceLon
        );

        // Créer le cylindre pour le puits
        const geometry = new THREE.CylinderGeometry(10, 10, well.depth, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        const wellMesh = new THREE.Mesh(geometry, material);
        wellMesh.position.set(x, -well.depth / 2, z);
        scene.add(wellMesh);

        // Ajouter la tête du puits comme sphère rouge
        const wellHeadGeometry = new THREE.SphereGeometry(15, 32, 32);
        const wellHeadMaterial = new THREE.MeshBasicMaterial({
          color: "black",
        });
        const wellHead = new THREE.Mesh(wellHeadGeometry, wellHeadMaterial);
        wellHead.position.set(x, 0, z);
        scene.add(wellHead);

        // Ajouter le nom du puits comme texte
        const canvas = document.createElement("canvas");
        canvas.width = 512;
        canvas.height = 128;
        const context = canvas.getContext("2d");
        context.font = "48px Arial";
        context.fillStyle = "black";
        context.textAlign = "center";
        context.fillText(well.name, canvas.width / 2, canvas.height / 2);

        const texture = new THREE.CanvasTexture(canvas);
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(300, 100, 1);
        sprite.position.set(x, 50, z); // Décalage vertical pour le texte au-dessus de la tête du puits
        scene.add(sprite);
      });
    },
  },
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
  overflow: auto;
}
</style>
======= >>>>>>> 72a0f42b297485bc866cc8ea1f28f86425ae1a9b
