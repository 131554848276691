<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <!-- Tabs to separate Tasks and Requests with badges for counts -->
            <v-tabs
              v-model="activeTab"
              background-color="grey lighten-4"
              light
              @change="tabChange"
            >
              <v-tab> Tasks </v-tab>
              <v-tab> Requests </v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <!-- Tasks Tab -->
              <v-tab-item>
                <listitemspage
                  :selitem="selitem"
                  :qSelect="Qselect_tasks"
                  :qSelectall="Qselectall_tasks"
                  :PageResponse="'tasksuser'"
                  :AllResponse="'userTasks'"
                  :headers="task_header"
                  :field="'CREATED_AT'"
                  :filename="'Tasks'"
                  :sheetname="'Tasks'"
                  :showedit="false"
                  :key="klist"
                  :Add="false"
                  :del="false"
                  :TypeScope="parseInt($store.state.me.id)"
                >
                </listitemspage>
              </v-tab-item>

              <!-- Requests Tab -->
              <v-tab-item>
                <listitemspage
                  :selitem="selitem"
                  :qSelect="Qselect_requests"
                  :qSelectall="Qselectall_requests"
                  :PageResponse="'requests'"
                  :AllResponse="'userRequests'"
                  :headers="task_header"
                  :field="'CREATED_AT'"
                  :filename="'Requests'"
                  :sheetname="'Requests'"
                  :showedit="false"
                  :key="klist"
                  :Add="false"
                  :del="false"
                  :TypeScope="parseInt($store.state.me.id)"
                >
                </listitemspage>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TASKS_PAGES from "@/graphql/Tasks/TASKS_PAGES.gql";
import REQUEST_PAGES from "@/graphql/Tasks/REQUEST_PAGES.gql";
import GET_TASKS from "@/graphql/Tasks/GET_TASKS.gql";
import GET_REQUESTS from "@/graphql/Tasks/GET_REQUESTS.gql";
import { datefr } from "@/utils/functions.js";
export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
  },
  data: () => ({
    tasks: [],
    search: "",
    filter: "all", // 'all', 'unread', or 'read'
    page: 1,
    perPage: 10,
    selitem: {},
    activeTab: 0, // 0 for Tasks, 1 for Requests
    klist: 250,
    task_header: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Title",
        value: "title",
        enum: "TITLE",
        selected: true,
      },
      {
        text: "Message",
        value: "message",
        enum: "MESSAGE",
        selected: true,
      },
      {
        text: "Requested By",
        value: "created_by_name",
        enum: "CREATED_BY_NAME",
        selected: true,
      },
      {
        text: "Created At",
        value: "created_at",
        enum: "CREATED_AT",
        slot: "date",
        selected: true,
      },
      {
        text: "Due Date",
        value: "due_date",
        enum: "DUE_DATE",
        slot: "date",
        selected: true,
      },
      {
        text: "Completed At",
        value: "completed_at",
        enum: "COMPLETED_AT",
        slot: "date",
        selected: true,
      },
      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        slot: "chip",
        color: "color",
        selected: true,
      },
    ],
  }),
  computed: {
    Qselect_tasks() {
      return TASKS_PAGES;
    },
    Qselect_requests() {
      return REQUEST_PAGES;
    },

    Qselectall_tasks() {
      return GET_TASKS;
    },
    Qselectall_requests() {
      return GET_REQUESTS;
    },
    datefr() {
      return datefr;
    },
  },
  methods: {
    tabChange() {
      this.task_header[3].text = this.activeTab === 0 ? "Requested By" : "User";
      this.task_header[3].value =
        this.activeTab === 0 ? "created_by_name" : "user_name";
      this.task_header[3].enum =
        this.activeTab === 0 ? "CREATED_BY_NAME" : "USER_NAME";
    },
  },
  mounted() {
    this.tabChange();
  },
};
</script>
